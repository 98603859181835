"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".page-top-bar[_ngcontent-%COMP%]{display:-webkit-box;display:-ms-flexbox;display:flex;position:relative;margin:50px auto 0;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;width:100%;height:70px;z-index:99}.page-top-bar[_ngcontent-%COMP%]   a[_ngcontent-%COMP%]:nth-child(1){height:100%}.page-top-bar[_ngcontent-%COMP%]   .header-logo[_ngcontent-%COMP%]{height:70%;width:auto}.page-top-bar[_ngcontent-%COMP%]   .envInfo[_ngcontent-%COMP%]{position:absolute;margin:0 auto;padding:5px 15px;color:#fff;font-weight:bolder;background-color:rgba(205,92,92,.5);top:-30px}"];
exports.styles = styles;
