import { AfterViewInit, ApplicationRef, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RegistrationFormProvider } from '../../services/registration-form.provider';
import { RegistrationProvider } from '../../services/registration.provider';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { VcMap } from '../../util/vc-map';

@Component({
  selector: 'app-step1',
  templateUrl: './step1.component.html',
  styleUrls: ['./step1.component.less']
})
export class Step1Component implements OnInit, AfterViewInit {
  public registrationForm: FormGroup;
  public signinWithSocial = false;

  placholders: any;

  constructor(
    private applicationRef: ApplicationRef,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private registrationProvider: RegistrationProvider,
    private registrationFormProvider: RegistrationFormProvider,
    private translation: TranslateService
  ) {
    this.registrationForm = registrationFormProvider.registrationForm;
  }

  passwordInputFocused = false;

  ngOnInit() {
    this.translation
      .get('placeholders')
      .pipe(take(1))
      .subscribe(data => (this.placholders = data));
    this.initRouteInfo();
  }

  ngAfterViewInit(): void {
    setTimeout(() => document.querySelector<HTMLInputElement>('[name="firstName"]').focus(), 300);
  }

  page1Valid() {
    return this.registrationFormProvider.isStep1Valid();
  }

  showValidationErrors() {
    this.registrationFormProvider.step1MarkAsTouched();
  }

  goToStep2() {
    if (!this.page1Valid()) {
      this.showValidationErrors();
    } else {
      this.router.navigate(['..', 'step2'], {
        relativeTo: this.activatedRoute
      });
    }
  }

  private initRouteInfo(): void {
    const routeParams = VcMap.fromRouteParamMap(this.activatedRoute.snapshot.queryParamMap);
    if (routeParams.hasAnyValue()) {
      this.updateForm(routeParams);
      this.setExtraData(routeParams);
    }
  }

  private updateForm(routeParams: VcMap<string, string>): void {
    routeParams.forEach((value, key) => {
      switch (key) {
        case 'country':
          this.registrationForm.get(key).setValue(value.toLowerCase());
          break;
        default:
          if (this.registrationForm.get(key)) {
            this.registrationForm.get(key).setValue(value);
          }
          break;
      }
    });
  }

  private setExtraData(routeParams: VcMap<string, string>): void {
    const integrationName = routeParams.get('extra_data[integration][name]');
    const integrationAccessToken = routeParams.get('extra_data[integration][configuration][accessToken]');
    if (integrationName && integrationAccessToken) {
      this.registrationProvider.mewsExtraData = {
        integration: {
          name: integrationName,
          configuration: {
            accessToken: integrationAccessToken
          }
        }
      };
    }
  }

  get validationTipsErrors() {
    const validators = {
      numbers: false,
      mixedCase: false,
      specialChars: false,
      length: false
    };

    return { ...validators, ...this.registrationForm.controls['password'].errors };
  }

  get isPasswordTipsOpen() {
    if (this.passwordInputFocused) {
      return true;
    }

    // if (!!this.registrationForm.controls['password'].value) {
    //   return true;
    // }

    return this.registrationForm.controls['password'].invalid && this.registrationForm.controls['password'].touched;
  }
}
