<div class="container">
  <div class="row mt-2">
    <div class="m-auto">
      <div class="alert box">
        <h1 class="err-code">404</h1>
        <h1 class="text-danger text-center">This page doesn't exist</h1>

        <div class="d-flex pt-5 pb-2">
          <a class="btn  btn-dark btn-lg m-auto" routerLink="/">HOME</a>
        </div>
      </div>
    </div>
  </div>
</div>
