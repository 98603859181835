"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var core_2 = require("@ngx-translate/core");
var application_service_1 = require("./services/application.service");
var Locale_enum_1 = require("./shared/Locale.enum");
var router_1 = require("@angular/router");
var registration_form_provider_1 = require("./services/registration-form.provider");
var registration_provider_1 = require("./services/registration.provider");
var forms_1 = require("@angular/forms");
var operators_1 = require("rxjs/operators");
var analytics_service_1 = require("./services/analytics.service");
var angular_google_tag_manager_1 = require("angular-google-tag-manager");
var environment_1 = require("../environments/environment");
var platform_browser_1 = require("@angular/platform-browser");
var store_1 = require("@ngrx/store");
var impact_tracking_data_actions_1 = require("./store/actions/impact-tracking-data.actions");
var cookieService = require('cookie');
var AppComponent = /** @class */ (function () {
    function AppComponent(activatedRoute, router, registrationFormProvider, registrationProvider, applicationService, translate, analytics, injector, metaService, store) {
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.registrationFormProvider = registrationFormProvider;
        this.registrationProvider = registrationProvider;
        this.applicationService = applicationService;
        this.translate = translate;
        this.analytics = analytics;
        this.injector = injector;
        this.metaService = metaService;
        this.store = store;
        this.params = {};
        this.tooltips = this.applicationService.getToolTipsTexts();
        this.registrationProvider.params = this.params;
        this.analytics.start();
        if (environment_1.environment.production) {
            var gtmService = this.injector.get(angular_google_tag_manager_1.GoogleTagManagerService);
            gtmService.addGtmToDom();
        }
        if (environment_1.environment.ENV_NAME !== 'production') {
            this.metaService.addTag({
                name: 'robots',
                content: 'noindex'
            });
        }
    }
    AppComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.initTranslationService();
        this.initFormFromParam();
        this.initImpactTrackingParams();
        this.activatedRoute.queryParams.pipe(operators_1.filter(function (params) { return !!Object.keys(params).length; })).subscribe(function (params) {
            _this.params = tslib_1.__assign({}, _this.params, params);
            _this.initTranslationService();
            _this.initFormFromParam();
            _this.registrationProvider.params = tslib_1.__assign({}, params);
        });
    };
    AppComponent.prototype.initFormFromParam = function () {
        this.initReseller();
        this.initLang();
        this.params.email && this.registrationFormProvider.registrationForm.patchValue({ email: this.params.email });
        this.params.country &&
            this.registrationFormProvider.registrationForm.patchValue({ country: this.params.country.toLowerCase() });
        this.params.lang &&
            this.registrationFormProvider.registrationForm.addControl('lang', new forms_1.FormControl(this.params.lang));
    };
    AppComponent.prototype.initTranslationService = function () {
        this.translate.setDefaultLang(Locale_enum_1.Locale.DEFAULT);
        this.translate.use(this.params.lang || this.applicationService.getLocale());
    };
    AppComponent.prototype.initReseller = function () {
        var cookies = cookieService.parse(document.cookie);
        var resellerHash = cookies.campaign_broker || this.params.resellerHash;
        this.params.resellerHash = resellerHash;
        if (resellerHash) {
            this.registrationFormProvider.registrationForm.addControl('resellerHash', new forms_1.FormControl(resellerHash));
        }
    };
    AppComponent.prototype.initLang = function () {
        var cookies = cookieService.parse(document.cookie);
        this.params.lang =
            cookies.dashboardLocale || this.params.lang || this.applicationService.getLocale() || Locale_enum_1.Locale.DEFAULT;
        this.registrationFormProvider.registrationForm.addControl('lang', new forms_1.FormControl(this.params.lang));
    };
    AppComponent.prototype.initImpactTrackingParams = function () {
        var cookies = cookieService.parse(document.cookie);
        var urlParams = new URLSearchParams(window.location.search);
        var irpid = urlParams.get('irpid') || cookies.irpid;
        var irclickid = urlParams.get('irclickid') || cookies.irclickid;
        if (irpid) {
            this.store.dispatch(new impact_tracking_data_actions_1.UpdateIrpid(irpid));
        }
        if (irclickid) {
            this.store.dispatch(new impact_tracking_data_actions_1.UpdateIrclickid(irclickid));
        }
    };
    AppComponent.prototype.getOutletAnimationTransitionName = function (o) {
        var name = o.isActivated && o.activatedRouteData && o.activatedRouteData.triggerName;
        return name;
    };
    return AppComponent;
}());
exports.AppComponent = AppComponent;
