"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["input[_ngcontent-%COMP%], select[_ngcontent-%COMP%]{border-color:#d8d8d8;border-radius:0}.invalid[_ngcontent-%COMP%]{border:1px solid #e84a35!important;border-right:2px solid}.error-field[_ngcontent-%COMP%]{font-family:-apple-system,BlinkMacSystemFont,segoe ui,roboto,oxygen,ubuntu,cantarell,fira sans,droid sans,helvetica neue,sans-serif;font-size:12px;color:#f55753;display:block;padding-left:10px}@media (max-width:400px){.button-mobile-w-100[_ngcontent-%COMP%]   button[_ngcontent-%COMP%]{width:100%}}.m-t-0[_ngcontent-%COMP%]{margin-top:0!important}"];
exports.styles = styles;
