"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./text-carousel.component.less.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@ngx-translate/core");
var i3 = require("@angular/common");
var i4 = require("./text-carousel.component");
var i5 = require("../services/platform.service");
var styles_TextCarouselComponent = [i0.styles];
var RenderType_TextCarouselComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TextCarouselComponent, data: { "animation": [{ type: 7, name: "textFlyAnimation", definitions: [{ type: 1, expr: "* <=> *", animation: { type: 3, steps: [{ type: 11, selector: ":enter", animation: [{ type: 6, styles: { position: "absolute", transform: "translateX(1000px)", opacity: 0, width: "100%" }, offset: null }, { type: 4, styles: { type: 6, styles: { transform: "translateX(0)", opacity: 1, width: "*" }, offset: null }, timings: "400ms ease-out" }], options: { optional: true } }, { type: 11, selector: ":leave", animation: [{ type: 6, styles: { position: "absolute", transform: "translateX(0)", width: "100%" }, offset: null }, { type: 4, styles: { type: 6, styles: { transform: "translateX(-1000px)", width: "0", opacity: 0 }, offset: null }, timings: "400ms ease-in" }], options: { optional: true } }], options: null }, options: null }], options: {} }] } });
exports.RenderType_TextCarouselComponent = RenderType_TextCarouselComponent;
function View_TextCarouselComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_v.parent.context.$implicit)); _ck(_v, 1, 0, currVal_0); }); }
function View_TextCarouselComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [["class", "mb-0"]], [[24, "@textFlyAnimation", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TextCarouselComponent_2)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.currentIndex === _v.context.index); _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.shouldAnimate ? _co.currentIndex : null); _ck(_v, 0, 0, currVal_0); }); }
function View_TextCarouselComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 23, "div", [["class", "wrapper text-wrapper-carousel"]], null, [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (_co.pause() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (_co.unpause() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "p", [["class", "question"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TextCarouselComponent_1)), i1.ɵdid(6, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 16, "div", [["class", "action-row ml-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "buttons ml-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "button", [["class", "left ml-0"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.previous() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "i", [["class", "fal fa-angle-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "button", [["class", "right"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.next() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 0, "i", [["class", "fal fa-angle-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 3, "div", [["class", "progressBar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 2, "div", [["class", "progressBar2"]], null, null, null, null, null)), i1.ɵdid(15, 278528, null, 0, i3.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(16, { width: 0, transition: 1 }), (_l()(), i1.ɵeld(17, 0, null, null, 6, "div", [["class", "progress-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 1, "span", [["class", "current-index"]], null, null, null, null, null)), (_l()(), i1.ɵted(19, null, ["", ""])), (_l()(), i1.ɵeld(20, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["/"])), (_l()(), i1.ɵeld(22, 0, null, null, 1, "span", [["class", "total"]], null, null, null, null, null)), (_l()(), i1.ɵted(23, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.tipsTexts; _ck(_v, 6, 0, currVal_1); var currVal_2 = _ck(_v, 16, 0, _co.progressBarLength, _co.progress2TransitionWithStyle); _ck(_v, 15, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("didUKnow")); _ck(_v, 2, 0, currVal_0); var currVal_3 = (_co.currentIndex + 1); _ck(_v, 19, 0, currVal_3); var currVal_4 = _co.total; _ck(_v, 23, 0, currVal_4); }); }
exports.View_TextCarouselComponent_0 = View_TextCarouselComponent_0;
function View_TextCarouselComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-text-carousel", [], null, null, null, View_TextCarouselComponent_0, RenderType_TextCarouselComponent)), i1.ɵdid(1, 114688, null, 0, i4.TextCarouselComponent, [i5.PlatformService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_TextCarouselComponent_Host_0 = View_TextCarouselComponent_Host_0;
var TextCarouselComponentNgFactory = i1.ɵccf("app-text-carousel", i4.TextCarouselComponent, View_TextCarouselComponent_Host_0, { tipsTexts: "tipsTexts" }, {}, []);
exports.TextCarouselComponentNgFactory = TextCarouselComponentNgFactory;
