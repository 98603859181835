import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { environment as env } from '../../environments/environment';

env.production &&
  Sentry.init({
    dsn: env.SENTRY_DNS,
    environment: env.ENV_NAME
  });

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}
  handleError(error) {
    env.production && Sentry.captureException(error.originalError || error);
    throw error;
  }
}
