"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./error-displayer.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/common");
var i3 = require("./error-displayer.component");
var i4 = require("../../../services/registration-form.provider");
var styles_ErrorDisplayerComponent = [i0.styles];
var RenderType_ErrorDisplayerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ErrorDisplayerComponent, data: {} });
exports.RenderType_ErrorDisplayerComponent = RenderType_ErrorDisplayerComponent;
function View_ErrorDisplayerComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [["class", "errors"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fal fa-triangle-exclamation"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["\u00A0", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit; _ck(_v, 2, 0, currVal_0); }); }
function View_ErrorDisplayerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ErrorDisplayerComponent_3)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ErrorDisplayerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "section", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ErrorDisplayerComponent_2)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errors.slice(0, 1); _ck(_v, 2, 0, currVal_0); }, null); }
function View_ErrorDisplayerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ErrorDisplayerComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.errors && _co.show); _ck(_v, 1, 0, currVal_0); }, null); }
exports.View_ErrorDisplayerComponent_0 = View_ErrorDisplayerComponent_0;
function View_ErrorDisplayerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-error-displayer", [], null, null, null, View_ErrorDisplayerComponent_0, RenderType_ErrorDisplayerComponent)), i1.ɵdid(1, 245760, null, 0, i3.ErrorDisplayerComponent, [i4.RegistrationFormProvider], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_ErrorDisplayerComponent_Host_0 = View_ErrorDisplayerComponent_Host_0;
var ErrorDisplayerComponentNgFactory = i1.ɵccf("app-error-displayer", i3.ErrorDisplayerComponent, View_ErrorDisplayerComponent_Host_0, { emRequired: "emRequired", emPhoneInvalid: "emPhoneInvalid", emMinLength: "emMinLength", emEmail: "emEmail", emNonBusinessEmail: "emNonBusinessEmail", emMax: "emMax", emMin: "emMin", emDomainNotAviable: "emDomainNotAviable", emDomainIncorrect: "emDomainIncorrect", emDefault: "emDefault", emDomainExist: "emDomainExist", emNoDomainForEmailError: "emNoDomainForEmailError", emIncorrectUrl: "emIncorrectUrl", appFieldName: "appFieldName", form: "form" }, {}, []);
exports.ErrorDisplayerComponentNgFactory = ErrorDisplayerComponentNgFactory;
