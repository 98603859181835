import { Injectable } from '@angular/core';
import { AnalyticsClient, AnalyticsEventNames } from './analyticsClient.interface';

@Injectable()
export class ConsoleLogAnalyticsService implements AnalyticsClient {
  constructor() {}

  pageViewEvent(url) {
    console.log(AnalyticsEventNames.PAGE_VIEW_EVENT, 'event fired:', url);
  }
}
