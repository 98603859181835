import { Component, Input, OnInit } from '@angular/core';
import { animate, group, query, style, transition, trigger, useAnimation } from '@angular/animations';
import { interval, timer } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { PlatformService } from '../services/platform.service';

const time = '400ms';

@Component({
  selector: 'app-text-carousel',
  templateUrl: './text-carousel.component.html',
  styleUrls: ['./text-carousel.component.less'],
  animations: [
    trigger('textFlyAnimation', [
      transition(
        '* <=> *',
        group([
          query(
            ':enter',
            [
              style({ position: 'absolute', transform: 'translateX(1000px)', opacity: 0, width: '100%' }),
              animate(time + ' ease-out', style({ transform: 'translateX(0)', opacity: 1, width: '*' }))
            ],
            { optional: true }
          ),
          query(
            ':leave',
            [
              style({ position: 'absolute', transform: 'translateX(0)', width: '100%' }),
              animate(time + ' ease-in', style({ transform: 'translateX(-1000px)', width: '0', opacity: 0 }))
            ],
            { optional: true }
          )
        ])
      )
    ])
  ]
})
export class TextCarouselComponent implements OnInit {
  private paused = false;
  shouldAnimate: boolean;
  progress2TransitionWithStyle = `width ${time} ease-in`;

  @Input()
  set tipsTexts(value: string[]) {
    this._textToShow = value;
    this.total = value && value.length;
  }
  get tipsTexts() {
    return this._textToShow;
  }

  private _textToShow: string[];
  currentIndex = 0;
  total = 0;
  progressBarLength: string | number = 0;
  constructor(private platform: PlatformService) {}

  ngOnInit() {
    this.shouldAnimate = this.checkAnimationIsSupported();
    this.updateProgressBar2Length();
    this.startCarousel();
  }

  next() {
    if (this.currentIndex + 1 === this.total) {
      this.currentIndex = 0;
    } else {
      this.currentIndex = this.currentIndex + 1;
    }
    this.updateProgressBar2Length();
  }

  previous() {
    if (this.currentIndex + 1 === 1) {
      this.currentIndex = this.total - 1;
    } else {
      this.currentIndex = this.currentIndex - 1;
    }
    this.updateProgressBar2Length();
  }

  private updateProgressBar2Length() {
    this.progressBarLength = Math.floor(((this.currentIndex + 1) / this.total) * 100) + '%';
  }

  pause() {
    this.paused = true;
  }

  unpause() {
    this.paused = false;
  }

  private startCarousel() {
    timer(1000, 5000)
      .pipe(filter(() => !this.paused))
      .subscribe(() => this.next());
  }

  private checkAnimationIsSupported() {
    return this.platform.isChrome() || this.platform.isFirefox() || this.platform.isOpera() || false;
  }
}
