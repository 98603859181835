import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { tap } from 'rxjs/operators';
import { CurrenciesService } from '../../services/currencies.service';

@Injectable()
export class Step2Resolver implements Resolve<any> {
  constructor(private http: HttpClient, private readonly currenciesService: CurrenciesService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return forkJoin(this.getCountries(), this.getTimeZones(), this.currenciesService.getCurrencies()).pipe(
      tap(result => console.log('resolve ', result))
    );
  }

  private getCountries = () => this.http.get('assets/countries.json');
  private getTimeZones = () => this.http.get('assets/country2timezone.json');
}
