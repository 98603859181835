import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { RegistrationFormProvider } from '../../../services/registration-form.provider';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { AbstractControlStatus } from '@angular/forms/src/directives/ng_control_status';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-vcinput',
  templateUrl: './vcinput.component.html',
  styleUrls: ['./vcinput.component.scss']
})
export class VcinputComponent implements OnInit {
  public hideExtraText = false;
  public controlObject: AbstractControl;
  @Input() public form: FormGroup;
  public hasFocus = false;

  @ViewChild('input') input: ElementRef;

  @Input() domainExist: boolean;
  @Input() myControlName: string;
  @Input() inputType = 'text';
  @Input() placeholder: string;
  @Input() fieldRequire: boolean;
  @Input() defaultValue: string | null;
  @Input() order: number;
  @Input() customText: string;
  @Input() label: string;
  @Input() tooltip: string;
  @Input() emMax;
  @Output() onEnter = new EventEmitter();
  @Input() disableErrorHandling = false;
  tooltipText: string;
  errorsTranslations: any;

  @Input()
  set value(value: string) {
    if (this.input) {
      this.input.nativeElement.value = value;
    }
  }

  @Output() valueChange = new EventEmitter<string>();
  @Output('onBlur') blurEE = new EventEmitter<void>();
  @Output('onFocus') onFocusEvent: EventEmitter<any> = new EventEmitter<void>();

  public showCustomText = true;

  constructor(private registrationFormProvider: RegistrationFormProvider, private translation: TranslateService) {}

  ngOnInit() {
    this.form = this.form || this.registrationFormProvider.registrationForm;
    this.controlObject = this.form.get(this.myControlName);
    this.translation
      .get('tooltips.domainTooltip')
      .pipe(take(1))
      .subscribe(data => (this.tooltipText = data));
    this.translation
      .get('errors')
      .pipe(take(1))
      .subscribe(errorsTranslation => (this.errorsTranslations = errorsTranslation));
  }

  onInputFocus() {
    this.hasFocus = true;
    this.onFocusEvent.emit();
  }

  onBlur(event: Event) {
    this.hasFocus = false;
    if ((<HTMLInputElement>event.target).value === '') this.clearField(); // tslint:disable-line
    this.blurEE.emit();
  }

  private clearField() {
    this.controlObject.setValue('');
  }

  onKeyPress(event: KeyboardEvent) {
    const target = <HTMLInputElement>event.target;
    this.valueChange.emit(target.value);
    if (event.code === 'Enter') target.blur(); // tslint:disable-line
  }

  showDomainExistError() {
    return this.myControlName === 'domain' && this.form.get('domain').hasError('domainExist');
  }

  hasError() {
    return this.form.get(this.myControlName).touched && this.form.get(this.myControlName).invalid;
  }
}
