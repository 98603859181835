"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var environment_1 = require("../../environments/environment");
var http_1 = require("@angular/common/http");
var operators_1 = require("rxjs/operators");
var i0 = require("@angular/core");
var i1 = require("@angular/common/http");
var LoginService = /** @class */ (function () {
    function LoginService(http) {
        this.http = http;
        this.BASE_URL = environment_1.environment.API_ENDPOINT;
        this.APP_URL = environment_1.environment.APP_URL;
        this.DOMAINS_URL = 'user-domains';
        this.AUTHORIZE = 'authorize';
    }
    LoginService.prototype.getDomainsForEmail = function (clientEmail) {
        var email = encodeURIComponent(clientEmail);
        return this.http.get(this.BASE_URL + "/" + this.DOMAINS_URL + "?email=" + email).pipe(operators_1.tap(console.log), operators_1.map(function (resp) { return resp.domains; }));
    };
    LoginService.prototype.login = function (_a) {
        var email = _a.email, password = _a.password, subdomain = _a.subdomain;
        var authHeader = btoa(email.concat(':', password));
        var headers = { Authorization: "Basic " + authHeader };
        return this.http
            .post(this.BASE_URL + "/" + this.AUTHORIZE, { subdomain: subdomain }, { headers: headers })
            .pipe();
    };
    LoginService.prototype.goToDashboardWithJwtToken = function (jwtToken) {
        window.location.href = this.APP_URL + "/auth-jwt-token?accessToken=" + jwtToken;
    };
    LoginService.ngInjectableDef = i0.defineInjectable({ factory: function LoginService_Factory() { return new LoginService(i0.inject(i1.HttpClient)); }, token: LoginService, providedIn: "root" });
    return LoginService;
}());
exports.LoginService = LoginService;
