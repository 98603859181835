export enum Locale {
  DEFAULT = 'en',
  en = 'en',
  de = 'de',
  pl = 'pl'
}

export namespace Locale {
  export function getAvaiableLocale() {
    return Object.keys(Locale);
  }
}
