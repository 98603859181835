"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var PlatformService = /** @class */ (function () {
    function PlatformService() {
        this.isChrome = function () { return /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor); };
        this.isFirefox = function () { return /Firefox/.test(navigator.userAgent); };
        this.isOpera = function () { return /OPR/.test(navigator.userAgent); };
    }
    PlatformService.ngInjectableDef = i0.defineInjectable({ factory: function PlatformService_Factory() { return new PlatformService(); }, token: PlatformService, providedIn: "root" });
    return PlatformService;
}());
exports.PlatformService = PlatformService;
