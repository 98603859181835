"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var registration_form_provider_1 = require("../../../services/registration-form.provider");
var forms_1 = require("@angular/forms");
var ErrorDisplayerComponent = /** @class */ (function () {
    function ErrorDisplayerComponent(formProvider) {
        this.formProvider = formProvider;
        this.emRequired = 'Field is required';
        this.emPhoneInvalid = 'Phone is invalid';
        this.emMinLength = 'Minimal length is';
        this.emEmail = 'Email is not valid';
        this.emNonBusinessEmail = 'Please provide a business email address';
        this.emMax = 'This is to long, max length is';
        this.emIncorrectUrl = 'This url is incorrect';
    }
    Object.defineProperty(ErrorDisplayerComponent.prototype, "errors", {
        get: function () {
            var _this = this;
            if (!this.field) {
                return;
            }
            if (this.isEmptyObject(this.field.errors)) {
                return [];
            } // tslint:disable-line
            return Object.entries(this.field.errors).map(function (_a) {
                var errorCode = _a[0], errorObject = _a[1];
                if (errorCode === null) {
                    return null;
                }
                switch (errorCode) {
                    case 'noDomainForEmailError':
                        return _this.emNoDomainForEmailError; /*  */
                    case 'email':
                        return _this.emEmail; /* email:true */
                    case 'required' /* require */:
                        return _this.emRequired;
                    case 'phoneInvalid' /* phoneInvalid */:
                        return _this.emPhoneInvalid;
                    case 'minlength' /*{'minlength': {'requiredLength': minLength, 'actualLength': length}} :*/:
                        return _this.emMin + " (Currently " + errorObject.actualLength + ")";
                    case 'maxlength':
                        return (_this.emMax || "Company name length is to long, should be max 60 characters long"); /* {'max': {'max': max, 'actual': control.value}} */
                    case 'domainNotAviable':
                        return _this.emDomainNotAviable;
                    case 'domainIncorrect':
                        return _this.emDomainIncorrect;
                    case 'pattern':
                        return _this.emDomainIncorrect;
                    case 'domainExist':
                        return '';
                    case 'nonBusinessEmail':
                        return _this.emNonBusinessEmail;
                    case 'incorrectUrl':
                        return _this.emIncorrectUrl;
                    default:
                        return 'Field Error';
                }
            });
        },
        enumerable: true,
        configurable: true
    });
    ErrorDisplayerComponent.prototype.ngOnInit = function () {
        this.form = this.form || this.formProvider.registrationForm;
        this.field = this.form.get(this.appFieldName);
    };
    ErrorDisplayerComponent.prototype.isEmptyObject = function (obj) {
        for (var k in obj) {
            if (obj.hasOwnProperty(k)) {
                return false;
            }
        }
        return true;
    };
    Object.defineProperty(ErrorDisplayerComponent.prototype, "show", {
        get: function () {
            var _a = this.field, touched = _a.touched, invalid = _a.invalid, dirty = _a.dirty;
            return touched && invalid;
        },
        enumerable: true,
        configurable: true
    });
    ErrorDisplayerComponent.prototype.ngOnDestroy = function () { };
    return ErrorDisplayerComponent;
}());
exports.ErrorDisplayerComponent = ErrorDisplayerComponent;
