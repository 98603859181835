"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var environment_1 = require("../../environments/environment");
var PageHeaderComponent = /** @class */ (function () {
    function PageHeaderComponent() {
        this.mainWebsiteURL = environment_1.environment.MAIN_WEBSITE_URL;
        this.envName = environment_1.environment.ENV_NAME;
    }
    PageHeaderComponent.prototype.ngOnInit = function () { };
    return PageHeaderComponent;
}());
exports.PageHeaderComponent = PageHeaderComponent;
