"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./error404.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@angular/router");
var i3 = require("@angular/common");
var i4 = require("./error404.component");
var styles_Error404Component = [i0.styles];
var RenderType_Error404Component = i1.ɵcrt({ encapsulation: 0, styles: styles_Error404Component, data: {} });
exports.RenderType_Error404Component = RenderType_Error404Component;
function View_Error404Component_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "row mt-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 9, "div", [["class", "m-auto"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 8, "div", [["class", "alert box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h1", [["class", "err-code"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["404"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h1", [["class", "text-danger text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["This page doesn't exist"])), (_l()(), i1.ɵeld(8, 0, null, null, 3, "div", [["class", "d-flex pt-5 pb-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "a", [["class", "btn  btn-dark btn-lg m-auto"], ["routerLink", "/"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["HOME"]))], function (_ck, _v) { var currVal_2 = "/"; _ck(_v, 10, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 10).target; var currVal_1 = i1.ɵnov(_v, 10).href; _ck(_v, 9, 0, currVal_0, currVal_1); }); }
exports.View_Error404Component_0 = View_Error404Component_0;
function View_Error404Component_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-error404", [], null, null, null, View_Error404Component_0, RenderType_Error404Component)), i1.ɵdid(1, 114688, null, 0, i4.Error404Component, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_Error404Component_Host_0 = View_Error404Component_Host_0;
var Error404ComponentNgFactory = i1.ɵccf("app-error404", i4.Error404Component, View_Error404Component_Host_0, {}, {}, []);
exports.Error404ComponentNgFactory = Error404ComponentNgFactory;
