"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".errors[_ngcontent-%COMP%]{overflow:hidden;-webkit-animation:.3s ease-out forwards errors-fade-in;animation:.3s ease-out forwards errors-fade-in}@-webkit-keyframes errors-fade-in{from{max-height:0}to{max-height:100px}}@keyframes errors-fade-in{from{max-height:0}to{max-height:100px}}"];
exports.styles = styles;
