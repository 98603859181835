"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".alert[_ngcontent-%COMP%]{position:relative}.alert[_ngcontent-%COMP%]   .close-icon[_ngcontent-%COMP%]{position:absolute;display:block;top:3px;-webkit-transition:tranform .2s;transition:tranform .2s;right:4px;color:#721c248c}.alert[_ngcontent-%COMP%]   .close-icon[_ngcontent-%COMP%]:hover{-webkit-transition:tranform .2s;transition:tranform .2s;-webkit-transform:scale(1.05);transform:scale(1.05)}"];
exports.styles = styles;
