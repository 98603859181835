import { Injectable } from '@angular/core';
import { environment as ENV } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CurrenciesService {
  apiURLV2 = ENV.API_ENDPOINT_V2;

  constructor(private readonly httpClient: HttpClient) {}

  getCurrencies() {
    return this.httpClient.get(`${this.apiURLV2}/currencies`);
  }
}
