"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./login-page.component.less.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("./login-form/login-form.component.ngfactory");
var i3 = require("./login-form/login-form.component");
var i4 = require("@angular/forms");
var i5 = require("../services/login.service");
var i6 = require("../services/legal-content-links.service");
var i7 = require("./login-page.component");
var styles_LoginPageComponent = [i0.styles];
var RenderType_LoginPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoginPageComponent, data: {} });
exports.RenderType_LoginPageComponent = RenderType_LoginPageComponent;
function View_LoginPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-login-form", [], null, null, null, i2.View_LoginFormComponent_0, i2.RenderType_LoginFormComponent)), i1.ɵdid(1, 4308992, null, 0, i3.LoginFormComponent, [i4.FormBuilder, i5.LoginService, i6.LegalContentLinksService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_LoginPageComponent_0 = View_LoginPageComponent_0;
function View_LoginPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-login-page", [], null, null, null, View_LoginPageComponent_0, RenderType_LoginPageComponent)), i1.ɵdid(1, 114688, null, 0, i7.LoginPageComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_LoginPageComponent_Host_0 = View_LoginPageComponent_Host_0;
var LoginPageComponentNgFactory = i1.ɵccf("app-login-page", i7.LoginPageComponent, View_LoginPageComponent_Host_0, {}, {}, []);
exports.LoginPageComponentNgFactory = LoginPageComponentNgFactory;
