<div class="container-fluid pl-0 pr-0 h-100 d-flex">
  <div class="row  wrapper">
    <div class="col d-flex flex-column flex-grow-1 form-column">
      <app-page-header></app-page-header>

      <div class="router-outlet-wrapper position-relative"
           [@routerAnimation]="getOutletAnimationTransitionName(outlet)">
        <router-outlet #outlet="outlet"></router-outlet>
      </div>
    </div>
    <div class="col flex-grow-1 right-col d-none d-md-flex ">
      <div class="wrapper">
        <app-text-carousel [tipsTexts]="tooltips"></app-text-carousel>
      </div>
    </div>

  </div>
</div>

