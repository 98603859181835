"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var FacebookPixelAnalyticsService = /** @class */ (function () {
    function FacebookPixelAnalyticsService() {
    }
    FacebookPixelAnalyticsService.prototype.pageViewEvent = function (url) {
        fbq('track', 'PageView', { url: url });
    };
    return FacebookPixelAnalyticsService;
}());
exports.FacebookPixelAnalyticsService = FacebookPixelAnalyticsService;
