import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class LegalContentLinksService {
  linkUrl: string;
  constructor(private translationService: TranslateService) {
    this.linkUrl = environment.MAIN_WEBSITE_URL;
  }

  getTermsUrl() {
    return `${this.linkUrl}/${this.translationService.currentLang}/merchant-terms-and-conditions`;
  }
  getCookiePolicyUrl() {
    return `${this.linkUrl}/${this.translationService.currentLang}/cookie-policy/`;
  }
  getContactUrl() {
    return `${this.linkUrl}/${this.translationService.currentLang}/contact/`;
  }
}
