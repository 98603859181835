
<app-vcinput myControlName="companyName"
             [placeholder]="placeholders?.companyName"
             [fieldRequire]="true"
             label="labels.companyName"
             (valueChange)="onValueChangeCompanyName($event)"
             (onBlur)="validateDomain()"
             (onEnter)="onEnter.emit($event)"
></app-vcinput>

<app-vcinput myControlName="domain"
             [placeholder]="placeholders?.domain"
             customText=".vouchercart.com"
             [fieldRequire]="true"
             [domainExist]="domainExist"
             label="labels.domainName"
             [value]="domainFromCompanyName"
             (valueChange)="onValueChangeDomain($event)"
             tooltip="tooltips.domainTooltip"
             emMax="The URL of your sales page can not be longer than 60 characters"
             (onEnter)="onEnter.emit($event)"
></app-vcinput>


