import { ValidationErrors, ValidatorFn } from '@angular/forms';

const passwordValidator: ValidatorFn = function({ value }): ValidationErrors {
  const validationResult: PasswordValidationErrors = {};

  let isInvalid = false;

  const proxyHandler: Record<string, any> = {
    set(target, prop, data) {
      target[prop] = data;
      isInvalid = true;
      return true;
    }
  };

  const validationRController = new Proxy(validationResult, proxyHandler);

  if (!value || typeof value !== 'string') {
    return null;
  }

  // Check for both uppercase and lowercase letters
  if (!/(?=.*[A-Z])(?=.*[a-z])/g.test(value)) {
    validationRController.mixedCase = true;
  }

  // Check for special characters
  if (!/(?=.*[!"#$%&'*+,./:;<=>?@\[\]^_`{|}~-])/g.test(value)) {
    validationRController.specialChars = true;
  }

  if (!/(?=.*\d)/g.test(value)) {
    validationRController.numbers = true;
  }

  if (value.length <= 12) {
    validationRController.length = true;
  }

  return isInvalid ? validationResult : null;
};

interface PasswordValidationErrors extends ValidationErrors {
  numbers?: boolean;
  mixedCase?: boolean;
  specialChars?: boolean;
  length?: boolean;
}

export default passwordValidator;
