import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class RouteChangeListnerService {
  private routeChange = new ReplaySubject();

  readonly navigationEndUrl$ = this.routeChange.asObservable();

  constructor(private router: Router) {
    this.init();
  }

  private init() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map((event: NavigationEnd) => event.urlAfterRedirects)
      )
      .subscribe(this.routeChange);
  }
}
