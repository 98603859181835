"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_animations_transitions_names_enum_1 = require("../shared/animations/router-animations-transitions-names.enum");
var ɵ0 = { triggerName: router_animations_transitions_names_enum_1.LOGIN_PAGE };
exports.ɵ0 = ɵ0;
var LoginPageModule = /** @class */ (function () {
    function LoginPageModule() {
    }
    return LoginPageModule;
}());
exports.LoginPageModule = LoginPageModule;
