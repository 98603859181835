"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var passwordValidator = function (_a) {
    var value = _a.value;
    var validationResult = {};
    var isInvalid = false;
    var proxyHandler = {
        set: function (target, prop, data) {
            target[prop] = data;
            isInvalid = true;
            return true;
        }
    };
    var validationRController = new Proxy(validationResult, proxyHandler);
    if (!value || typeof value !== 'string') {
        return null;
    }
    // Check for both uppercase and lowercase letters
    if (!/(?=.*[A-Z])(?=.*[a-z])/g.test(value)) {
        validationRController.mixedCase = true;
    }
    // Check for special characters
    if (!/(?=.*[!"#$%&'*+,./:;<=>?@\[\]^_`{|}~-])/g.test(value)) {
        validationRController.specialChars = true;
    }
    if (!/(?=.*\d)/g.test(value)) {
        validationRController.numbers = true;
    }
    if (value.length <= 12) {
        validationRController.length = true;
    }
    return isInvalid ? validationResult : null;
};
var ɵ0 = passwordValidator;
exports.ɵ0 = ɵ0;
exports.default = passwordValidator;
