"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var operators_1 = require("rxjs/operators");
var login_service_1 = require("../../services/login.service");
var rxjs_1 = require("rxjs");
var email_validator_1 = require("../../registration/validators/email.validator");
var legal_content_links_service_1 = require("../../services/legal-content-links.service");
var LoginFormComponent = /** @class */ (function () {
    function LoginFormComponent(fb, loginService, legalContent) {
        this.fb = fb;
        this.loginService = loginService;
        this.legalContent = legalContent;
        this.contactURL = this.legalContent.getContactUrl();
        this.initForm();
    }
    LoginFormComponent.prototype.ngOnInit = function () {
        this.onSetEmail();
    };
    LoginFormComponent.prototype.initForm = function () {
        this.form = this.fb.group({
            email: ['', [forms_1.Validators.required, email_validator_1.emailValidator]],
            password: ['', [forms_1.Validators.required]],
            domains: this.fb.array([]),
            subdomain: ['', [forms_1.Validators.required]],
            rememberMe: [true]
        });
    };
    LoginFormComponent.prototype.addDomains = function (domains) {
        var _this = this;
        this.subdomainInvalid = false;
        var domainsFormCotrolls = domains.map(function (domain) { return _this.fb.control(domain); });
        this.setDomains(domainsFormCotrolls);
        if (!domains.length) {
            return;
        }
        this.form.patchValue({ subdomain: domains[0].name });
    };
    LoginFormComponent.prototype.getDomains = function () {
        return this.form.get('domains');
    };
    LoginFormComponent.prototype.setDomains = function (domains) {
        this.form.setControl('domains', this.fb.array(domains.slice()));
    };
    LoginFormComponent.prototype.onSetEmail = function () {
        var _this = this;
        var emailControl = this.form.get('email');
        emailControl.valueChanges
            .pipe(operators_1.startWith(this.form.controls.email.value), operators_1.filter(function (value) { return !emailControl.hasError('email') && !emailControl.hasError('require'); }), operators_1.debounceTime(300), operators_1.distinctUntilChanged(), operators_1.tap(function (email) { return (_this.searchingDomains = true); }), operators_1.switchMap(function (email) {
            return _this.loginService.getDomainsForEmail(email).pipe(operators_1.catchError(function (err) {
                _this.form.controls.email.setErrors({ noDomainForEmailError: true });
                return rxjs_1.of([]);
            }), operators_1.tap(function (_) { return (_this.searchingDomains = false); }));
        }))
            .subscribe(function (domains) { return _this.addDomains(domains); });
    };
    LoginFormComponent.prototype.login = function () {
        var _this = this;
        this.form.updateValueAndValidity();
        this.touchForm();
        if (this.form.valid) {
            this.logging = true;
            var _a = this.form.value, email = _a.email, password = _a.password, subdomain = _a.subdomain;
            this.loginService
                .login({ email: email, password: password, subdomain: subdomain })
                .pipe(operators_1.map(function (response) { return response.accessToken; }))
                .subscribe(function (token) { return _this.loginSuccess(token); }, function (error) { return _this.showWrongCredentialsError(error); });
        }
        else if (this.form.controls.subdomain.invalid &&
            this.form.controls.email.valid &&
            this.form.controls.password.valid) {
            this.subdomainInvalid = true;
            this.showWrongCredentialsError();
        }
    };
    LoginFormComponent.prototype.showWrongCredentialsError = function (error) {
        this.logging = false;
        this.form.controls.email.setErrors({ subdomainInvalid: true });
        this.form.controls.password.setErrors({ subdomainInvalid: true });
    };
    LoginFormComponent.prototype.onlyOneDomain = function () {
        return this.getDomains().length < 2;
    };
    LoginFormComponent.prototype.touchForm = function () {
        var _this = this;
        ['email', 'password', 'subdomain'].forEach(function (controlName) {
            _this.form.get(controlName).markAsTouched();
            _this.form.get(controlName).updateValueAndValidity();
        });
    };
    LoginFormComponent.prototype.fieldError = function (field, errorType) {
        var _a = this.form.get(field), invalid = _a.invalid, touched = _a.touched;
        if (errorType) {
            return invalid && touched && this.form.get(field).hasError(errorType);
        }
        return invalid && touched;
    };
    LoginFormComponent.prototype.loginSuccess = function (token) {
        this.loginService.goToDashboardWithJwtToken(token);
    };
    LoginFormComponent.prototype.ngAfterViewInit = function () {
        setTimeout(function () { return document.querySelector('[id="email"]').focus(); }, 300);
    };
    return LoginFormComponent;
}());
exports.LoginFormComponent = LoginFormComponent;
