
<section class="d-flex flex-column flex-grow-1">

  <p class="text-center text-uppercase title mb-1" i18n>
    {{'page2Header' | translate}}
  </p>
  <p class="subtitle">{{'page2subHeader' | translate}}</p>

  <form [formGroup]="registrationForm" class="d-flex flex-column flex-grow-1">
    <!--{{registrationForm.value | json}}-->
    <div class="form-group form-group-default form-group-default-select m-t-10"
         [ngClass]="{'focused': countryFocus}">
      <label for="country">{{'labels.country' | translate}}</label>
      <select
        #country
        name="country"
        id="country"
        class="form-control"
        formControlName="country"
        (focus)="focus('countryFocus')"
        (blur)="blur('countryFocus')"
      >
        <option disabled selected value> -- select an option --</option>
        <option *ngFor="let country of countryList"
                [value]="country.code.toLowerCase()">
          {{ country.name }}
        </option>
      </select>
    </div>
    <p class="error-field" *ngIf="isCountryError">{{'errors.notInThisCountry' | translate}}</p>
    <div class="form-group form-group-default form-group-default-select" *ngIf="showTimezoneSelect"
         [ngClass]="{'focused': timeZoneFocus}">
      <label class="field-require text-uppercase" for="timeZone" i18n>{{'labels.timeZone' | translate}}</label>
      <select id="timeZone"
              class="form-control"
              formControlName="timeZone"
              (focus)="focus('timeZoneFocus')"
              (blur)="blur('timeZoneFocus')"
      >
        <option value="" selected disabled hidden> -- select an option --</option>
        <option *ngFor="let zone of allZone"
                [value]="zone.key"
        >
          {{ zone.value}} - {{ zone.key }}
        </option>
      </select>
    </div>
    <div class="form-group form-group-default form-group-default-select" [ngClass]="{'focused': businessFocus, 'has-error': hasError('businessSector')}">
      <label for="businessSector" class="text-uppercase mb-0" i18n>
        {{'labels.bussinessSector' | translate}}
        {{'labels.bussinessSectorSubTitle' | translate}}
      </label>

      <select name="businessSector"
              id="businessSector"
              class="form-control"
              formControlName="businessSector"
              (focus)="focus('businessFocus')"
              (blur)="blur('businessFocus')"
              autofocus
      >
        <option disabled selected> {{'labels.selectOne' | translate}}</option>
        <option i18n value="hotel">{{ 'Hotel' | translate }}</option>
        <option i18n value="spa">{{ 'Spa' | translate }}</option>
        <option i18n value="leisure">{{ 'Leisure' | translate }}</option>
        <option i18n value="restaurant">{{ 'Restaurant' | translate }}</option>
        <option i18n value="retail">{{ 'Retail' | translate }}</option>
        <option i18n value="other">{{ 'Other' | translate }}</option>
      </select>
    </div>
    <app-error-displayer [appFieldName]="'businessSector'" [form]="registrationForm"></app-error-displayer>
    <app-vcinput #businessWebsite
                 inputType="text"
                 label="labels.businessWebsite" i18n-label
                 myControlName="website"
                 placeholder="e.g. www.some.business.com"
                 [fieldRequire]="false"
                 (onEnter)="submitForm()"

    >
    </app-vcinput>
    <app-company-name-domain-sync [domainExist]="false" (onEnter)="submit()">

    </app-company-name-domain-sync>

    <div *ngIf="false" class="form-group form-group-default form-group-default-select">
      <label for="saleCurrency" class="text-uppercase mb-0" i18n>
        {{'labels.saleCurrency' | translate}}
      </label>
      <select class="form-control"
              name="saleCurrency"
              id="saleCurrency"
              formControlName="saleCurrency">
        <option *ngFor="let currency of currencies" [value]="currency">
          {{currency}}
        </option>
      </select>
    </div>

    <div class="">
      <app-error500 *ngIf="is500ErrorVisible" (closeClick)="hide500Error()"></app-error500>
    </div>

    <div class="mt-auto">
      <div class="clearfix button-mobile-w-100 d-flex flex-column flex-sm-row">
        <button type="button"
          [disabled]="submitingForm | async"
          class="btn  btn-lg btn-default mr-sm-1  mb-1 mb-sm-0 col-sm-3 btn-bck"
          (click)="boBack()" >
          < {{'labels.back' | translate}}
        </button>
        <ng-container *ngIf="(registrationForm?.valid || registrationForm?.disabled); else noValid">
          <button type="button" class="btn btn-lg btn-primary flex-grow-1" (click)="submitForm()"
                  [disabled]="(submitingForm | async)">
            <span>{{'labels.letsStart' | translate}}</span>
            <img *ngIf="(submitingForm | async)" src="/assets/img/spinner.svg" width="12" height="12" alt="">
          </button>
        </ng-container>
        <ng-template #noValid>
          <button class="btn btn-lg btn-default  flex-grow-1" (click)="submitForm()"><span>{{'labels.letsStart' | translate}}</span></button>
        </ng-template>

      </div>
    </div>

    <div class="footer-text">
      <div class="col-12 small-text text-center ">
        <span class="">{{'labels.agreement1' | translate}}</span><br>
        <a class="text-primary" [href]="termsURL"
           target="_blank">{{'labels.agreement2' | translate}} </a>
        <span> {{'labels.agreement23and' | translate}} </span>
        <a class="text-primary" [href]="cookiePolicyURL"
           target="_blank">{{'labels.agreement3' | translate}} </a>.
      </div>
    </div>

  </form>
</section>



