"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["main[_ngcontent-%COMP%]{width:100%;padding-bottom:60px;background-color:#f7f7f7}.right-col[_ngcontent-%COMP%]{position:relative;background-color:#fff}.right-col[_ngcontent-%COMP%]:before{content:' ';display:block;position:absolute;top:0;left:0;width:100%;height:100%;background:url(/assets/img/background.jpg) center left/cover no-repeat;opacity:.6}.wrapper[_ngcontent-%COMP%]{height:100%;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1;margin-left:0;margin-right:0}@media (max-height:900px){.wrapper[_ngcontent-%COMP%]{overflow:auto}}.container-fluid[_ngcontent-%COMP%]{overflow:hidden}app-text-carousel[_ngcontent-%COMP%]{position:absolute;left:60px;right:0;bottom:60px;margin-right:60px;margin-top:60px}"];
exports.styles = styles;
