import {Directive, EventEmitter, HostBinding, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[appEmitEventOnEnter]'
})
export class EmitEventOnEnter {
  @Input('appEmitEventOnEnter') methodToCall: EventEmitter<void>;
  @HostListener('keypress', ['$event'])
  onKeyPress(key: KeyboardEvent) {
    if (this.keyIsEnter(key.code)) {
      if (this.methodToCall instanceof EventEmitter) {
        console.log('from enter direcetive');
        this.methodToCall.emit();
      }
    }
  }
  constructor() {}

  private keyIsEnter(keyCode: string): boolean {
    return keyCode === 'Enter';
  }
}
