"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var analyticsClient_interface_1 = require("./analyticsClient.interface");
var ConsoleLogAnalyticsService = /** @class */ (function () {
    function ConsoleLogAnalyticsService() {
    }
    ConsoleLogAnalyticsService.prototype.pageViewEvent = function (url) {
        console.log(analyticsClient_interface_1.AnalyticsEventNames.PAGE_VIEW_EVENT, 'event fired:', url);
    };
    return ConsoleLogAnalyticsService;
}());
exports.ConsoleLogAnalyticsService = ConsoleLogAnalyticsService;
