"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var ThankyouComponent = /** @class */ (function () {
    function ThankyouComponent(activatedRoute) {
        this.activatedRoute = activatedRoute;
    }
    ThankyouComponent.prototype.ngOnInit = function () {
        var url = this.activatedRoute.snapshot.queryParams.url;
        setTimeout(function () {
            window.location.href = url;
        }, 2000);
    };
    return ThankyouComponent;
}());
exports.ThankyouComponent = ThankyouComponent;
