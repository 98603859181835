"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var GoogleAnalyticsService = /** @class */ (function () {
    function GoogleAnalyticsService() {
    }
    GoogleAnalyticsService.prototype.pageViewEvent = function (url) {
        ga('set', 'page', url);
        ga('send', 'pageview');
    };
    return GoogleAnalyticsService;
}());
exports.GoogleAnalyticsService = GoogleAnalyticsService;
