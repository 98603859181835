"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var http_1 = require("@angular/common/http");
var registration_form_provider_1 = require("./registration-form.provider");
var environment_1 = require("../../environments/environment");
var RegistrationProvider = /** @class */ (function () {
    function RegistrationProvider(http, registrationForm) {
        this.http = http;
        this.formWasSubmited = false;
        this.domainNotAviable = false;
        this.extraData = {};
        this.BASE_URL = environment_1.environment.API_ENDPOINT;
        this.form = registrationForm.registrationForm;
    }
    RegistrationProvider.prototype.submit = function () {
        var _this = this;
        return new Promise(function (res, rej) {
            _this.http
                .post([_this.BASE_URL, 'register'].join('/'), _this.getSubmitData(), {
                observe: 'response'
            })
                .subscribe(function (ok) { return res(ok.body.redirect); }, function (fail) { return rej(fail); });
        });
    };
    RegistrationProvider.prototype.getSubmitData = function () {
        var extraData = {};
        if (this.mewsExtraData) {
            extraData = tslib_1.__assign({}, extraData, this.mewsExtraData);
        }
        if (Object.keys(this.extraData).length !== 0) {
            extraData = tslib_1.__assign({}, extraData, this.extraData);
        }
        return tslib_1.__assign({}, this.form.value, { extra_data: extraData });
    };
    RegistrationProvider.prototype.appendExtraData = function (extraData) {
        if (extraData === void 0) { extraData = {}; }
        this.extraData = tslib_1.__assign({}, this.extraData, extraData);
    };
    return RegistrationProvider;
}());
exports.RegistrationProvider = RegistrationProvider;
