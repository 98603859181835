<div
  class="form-group"
  [ngClass]="{
  'form-group-default': !customText,
  'has-error': hasError(),
  'focused': hasFocus,
  'ovflow': tooltip
  }"
  [formGroup]="form"
>
  <label [for]="myControlName"
         class="text-uppercase"
         [ngClass]="{'field-require': fieldRequire, 'm-r-10': !!customText}"

  >
    {{ label | translate }}
    <i *ngIf="tooltip"  class="fa fa-question-circle" [attr.data-title]="tooltipText"></i>
  </label>


  <ng-container *ngIf="customText; else noGroup">

    <div class="as-input-group">
      <div class="input-wrapper">

        <input [id]="myControlName"
               [name]="myControlName"
               [type]="inputType"
               class="form-control"
               [placeholder]="placeholder"
               [formControlName]="myControlName"
               (focus)="onInputFocus()"
               (blur)="onBlur($event)"
               (keyup)="onKeyPress($event)"
               [appEmitEventOnEnter]="onEnter"
        >
      </div>
      <span class="">{{customText}}</span>
    </div>
  </ng-container>
  <ng-template #noGroup>
    <input [id]="myControlName"
           [name]="myControlName"
           [type]="inputType"
           class="form-control"
           [placeholder]="placeholder"
           [formControlName]="myControlName"
           (focus)="onInputFocus()"
           (blur)="onBlur($event)"
           (keyup)="onKeyPress($event)"
           autocomplete="off"
           [appEmitEventOnEnter]="onEnter"
    >
  </ng-template>

</div>
<div *ngIf="!disableErrorHandling" class="error-area">
  <p  *ngIf="showDomainExistError()" class="text-danger"><i class="fal fa-triangle-exclamation"></i>&nbsp;{{'errors.domainExist' | translate}}</p>
  <app-error-displayer
                       [appFieldName]="myControlName"
                       [emRequired]="errorsTranslations?.fieldIsRequired"
                       [emPhoneInvalid]="errorsTranslations?.phoneInvalid"
                       [emEmail]="errorsTranslations?.emailIsIncorrect"
                       [emNonBusinessEmail]="errorsTranslations?.nonBusinessEmail"
                       [emMinLength]="errorsTranslations?.minimalLengthIs"
                       [emMax]="emMax || errorsTranslations?.thisIsToLong"
                       [emMin]="errorsTranslations?.errorMinimalLengthIs"
                       [emDomainNotAviable]="errorsTranslations?.thisUrlIsNotAviable"
                       [emDomainIncorrect]="errorsTranslations?.thisUrlIsIncorrect"
                       [emDefault]="errorsTranslations?.fieldHasError"
                       [emDomainExist]="errorsTranslations?.domainExist"
                       [emNoDomainForEmailError]="errorsTranslations?.noDomainForEmailError"
                       [form]="form"
  >
  </app-error-displayer>
</div>
