"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var ImpactTrackingDataActions = require("../actions/impact-tracking-data.actions");
var initialState = {
    irpid: null,
    irclickid: null
};
function impactTrackingDataReducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case ImpactTrackingDataActions.UPDATE_IRPID:
            return tslib_1.__assign({}, state, { irpid: action.payload });
        case ImpactTrackingDataActions.UPDATE_IRCLICKID:
            return tslib_1.__assign({}, state, { irclickid: action.payload });
        default:
            return state;
    }
}
exports.impactTrackingDataReducer = impactTrackingDataReducer;
exports.getImpactTrackingData = function (state) { return state; };
exports.getImpactTrackingDataIrpid = function (state) { return state.irpid; };
exports.getImpactTrackingDataIrclickid = function (state) { return state.irclickid; };
