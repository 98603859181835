import { Inject, Injectable } from '@angular/core';
import { RouteChangeListnerService } from './route-change.listner.service';
import { ANALYTICS_CLIENTS } from './analytics-clients/AnalyticsClientsInjectionToken';
import { AnalyticsClient, AnalyticsEventNames } from './analytics-clients/analyticsClient.interface';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  constructor(
    private routerChange: RouteChangeListnerService,
    @Inject(ANALYTICS_CLIENTS) private analyticsClients: AnalyticsClient[]
  ) {}

  start() {
    this.routerChangeNotification();
  }

  private routerChangeNotification() {
    this.routerChange.navigationEndUrl$
      .pipe(distinctUntilChanged())
      .subscribe(this.callEventOnEachClient(AnalyticsEventNames.PAGE_VIEW_EVENT));
  }

  private callEventOnEachClient = methodName => (...params) => {
    this.analyticsClients.forEach(client => client[methodName](params));
  };
}
