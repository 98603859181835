"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["input[_ngcontent-%COMP%], select[_ngcontent-%COMP%]{border-color:#d8d8d8;border-radius:0}.invalid[_ngcontent-%COMP%]{border:1px solid #e84a35!important;border-right:2px solid}  .custom-vc-input .has-error{margin-bottom:1rem}"];
exports.styles = styles;
