"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./error500.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("./error500.component");
var styles_Error500Component = [i0.styles];
var RenderType_Error500Component = i1.ɵcrt({ encapsulation: 0, styles: styles_Error500Component, data: {} });
exports.RenderType_Error500Component = RenderType_Error500Component;
function View_Error500Component_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [["class", "alert alert-danger"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Whoops, looks like something went wrong. "])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "fa fa-times close-icon"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeClick.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
exports.View_Error500Component_0 = View_Error500Component_0;
function View_Error500Component_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-error500", [], null, null, null, View_Error500Component_0, RenderType_Error500Component)), i1.ɵdid(1, 114688, null, 0, i2.Error500Component, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_Error500Component_Host_0 = View_Error500Component_Host_0;
var Error500ComponentNgFactory = i1.ɵccf("app-error500", i2.Error500Component, View_Error500Component_Host_0, {}, { closeClick: "closeClick" }, []);
exports.Error500ComponentNgFactory = Error500ComponentNgFactory;
