<div   *ngIf="open" @fadeIn @fadeOut>
  <div class="password-tips-loader">
    <div class="password-tips-loader__thumb" [style.width]="loaderWidth"></div>
  </div>
  <div class="validation-tips-list">
    <div class="validation-tips-blot" [class.active-validation-blot]="isValid('numbers')">
      <i class="far fa-check"></i>
      <span>{{ 'passwordTips.numbers' | translate }}</span>
    </div>
    <div class="validation-tips-blot" [class.active-validation-blot]="isValid('mixedCase')">
      <i class="far fa-check"></i>
      <span>{{ 'passwordTips.mixedCase' | translate }}</span>
    </div>
    <div class="validation-tips-blot" [class.active-validation-blot]="isValid('specialChars')">
      <i class="far fa-check"></i>
      <span>{{ 'passwordTips.symbols' | translate }}</span>
    </div>
    <div class="validation-tips-blot" [class.active-validation-blot]="isValid('length')">
      <i class="far fa-check"></i>
      <span>{{ 'passwordTips.minimalLength' | translate }}</span>
    </div>
  </div>
</div>
