"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var platform_service_1 = require("../services/platform.service");
var time = '400ms';
var TextCarouselComponent = /** @class */ (function () {
    function TextCarouselComponent(platform) {
        this.platform = platform;
        this.paused = false;
        this.progress2TransitionWithStyle = "width " + time + " ease-in";
        this.currentIndex = 0;
        this.total = 0;
        this.progressBarLength = 0;
    }
    Object.defineProperty(TextCarouselComponent.prototype, "tipsTexts", {
        get: function () {
            return this._textToShow;
        },
        set: function (value) {
            this._textToShow = value;
            this.total = value && value.length;
        },
        enumerable: true,
        configurable: true
    });
    TextCarouselComponent.prototype.ngOnInit = function () {
        this.shouldAnimate = this.checkAnimationIsSupported();
        this.updateProgressBar2Length();
        this.startCarousel();
    };
    TextCarouselComponent.prototype.next = function () {
        if (this.currentIndex + 1 === this.total) {
            this.currentIndex = 0;
        }
        else {
            this.currentIndex = this.currentIndex + 1;
        }
        this.updateProgressBar2Length();
    };
    TextCarouselComponent.prototype.previous = function () {
        if (this.currentIndex + 1 === 1) {
            this.currentIndex = this.total - 1;
        }
        else {
            this.currentIndex = this.currentIndex - 1;
        }
        this.updateProgressBar2Length();
    };
    TextCarouselComponent.prototype.updateProgressBar2Length = function () {
        this.progressBarLength = Math.floor(((this.currentIndex + 1) / this.total) * 100) + '%';
    };
    TextCarouselComponent.prototype.pause = function () {
        this.paused = true;
    };
    TextCarouselComponent.prototype.unpause = function () {
        this.paused = false;
    };
    TextCarouselComponent.prototype.startCarousel = function () {
        var _this = this;
        rxjs_1.timer(1000, 5000)
            .pipe(operators_1.filter(function () { return !_this.paused; }))
            .subscribe(function () { return _this.next(); });
    };
    TextCarouselComponent.prototype.checkAnimationIsSupported = function () {
        return this.platform.isChrome() || this.platform.isFirefox() || this.platform.isOpera() || false;
    };
    return TextCarouselComponent;
}());
exports.TextCarouselComponent = TextCarouselComponent;
