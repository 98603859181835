"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./company-name-domain-sync.component.css.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../vcinput/vcinput.component.ngfactory");
var i3 = require("../vcinput/vcinput.component");
var i4 = require("../../../services/registration-form.provider");
var i5 = require("@ngx-translate/core");
var i6 = require("./company-name-domain-sync.component");
var styles_CompanyNameDomainSyncComponent = [i0.styles];
var RenderType_CompanyNameDomainSyncComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CompanyNameDomainSyncComponent, data: {} });
exports.RenderType_CompanyNameDomainSyncComponent = RenderType_CompanyNameDomainSyncComponent;
function View_CompanyNameDomainSyncComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-vcinput", [["label", "labels.companyName"], ["myControlName", "companyName"]], null, [[null, "valueChange"], [null, "onBlur"], [null, "onEnter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = (_co.onValueChangeCompanyName($event) !== false);
        ad = (pd_0 && ad);
    } if (("onBlur" === en)) {
        var pd_1 = (_co.validateDomain() !== false);
        ad = (pd_1 && ad);
    } if (("onEnter" === en)) {
        var pd_2 = (_co.onEnter.emit($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_VcinputComponent_0, i2.RenderType_VcinputComponent)), i1.ɵdid(1, 114688, null, 0, i3.VcinputComponent, [i4.RegistrationFormProvider, i5.TranslateService], { myControlName: [0, "myControlName"], placeholder: [1, "placeholder"], fieldRequire: [2, "fieldRequire"], label: [3, "label"] }, { onEnter: "onEnter", valueChange: "valueChange", blurEE: "onBlur" }), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-vcinput", [["customText", ".vouchercart.com"], ["emMax", "The URL of your sales page can not be longer than 60 characters"], ["label", "labels.domainName"], ["myControlName", "domain"], ["tooltip", "tooltips.domainTooltip"]], null, [[null, "valueChange"], [null, "onEnter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = (_co.onValueChangeDomain($event) !== false);
        ad = (pd_0 && ad);
    } if (("onEnter" === en)) {
        var pd_1 = (_co.onEnter.emit($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_VcinputComponent_0, i2.RenderType_VcinputComponent)), i1.ɵdid(3, 114688, null, 0, i3.VcinputComponent, [i4.RegistrationFormProvider, i5.TranslateService], { domainExist: [0, "domainExist"], myControlName: [1, "myControlName"], placeholder: [2, "placeholder"], fieldRequire: [3, "fieldRequire"], customText: [4, "customText"], label: [5, "label"], tooltip: [6, "tooltip"], emMax: [7, "emMax"], value: [8, "value"] }, { onEnter: "onEnter", valueChange: "valueChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "companyName"; var currVal_1 = ((_co.placeholders == null) ? null : _co.placeholders.companyName); var currVal_2 = true; var currVal_3 = "labels.companyName"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.domainExist; var currVal_5 = "domain"; var currVal_6 = ((_co.placeholders == null) ? null : _co.placeholders.domain); var currVal_7 = true; var currVal_8 = ".vouchercart.com"; var currVal_9 = "labels.domainName"; var currVal_10 = "tooltips.domainTooltip"; var currVal_11 = "The URL of your sales page can not be longer than 60 characters"; var currVal_12 = _co.domainFromCompanyName; _ck(_v, 3, 0, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12); }, null); }
exports.View_CompanyNameDomainSyncComponent_0 = View_CompanyNameDomainSyncComponent_0;
function View_CompanyNameDomainSyncComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-company-name-domain-sync", [], null, null, null, View_CompanyNameDomainSyncComponent_0, RenderType_CompanyNameDomainSyncComponent)), i1.ɵdid(1, 114688, null, 0, i6.CompanyNameDomainSyncComponent, [i4.RegistrationFormProvider, i5.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_CompanyNameDomainSyncComponent_Host_0 = View_CompanyNameDomainSyncComponent_Host_0;
var CompanyNameDomainSyncComponentNgFactory = i1.ɵccf("app-company-name-domain-sync", i6.CompanyNameDomainSyncComponent, View_CompanyNameDomainSyncComponent_Host_0, { domainExist: "domainExist" }, { onEnter: "onEnter" }, []);
exports.CompanyNameDomainSyncComponentNgFactory = CompanyNameDomainSyncComponentNgFactory;
