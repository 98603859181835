"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var http_1 = require("@angular/common/http");
var operators_1 = require("rxjs/operators");
var currencies_service_1 = require("../../services/currencies.service");
var Step2Resolver = /** @class */ (function () {
    function Step2Resolver(http, currenciesService) {
        var _this = this;
        this.http = http;
        this.currenciesService = currenciesService;
        this.getCountries = function () { return _this.http.get('assets/countries.json'); };
        this.getTimeZones = function () { return _this.http.get('assets/country2timezone.json'); };
    }
    Step2Resolver.prototype.resolve = function (route, state) {
        return rxjs_1.forkJoin(this.getCountries(), this.getTimeZones(), this.currenciesService.getCurrencies()).pipe(operators_1.tap(function (result) { return console.log('resolve ', result); }));
    };
    return Step2Resolver;
}());
exports.Step2Resolver = Step2Resolver;
