"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var forms_1 = require("@angular/forms");
var http_1 = require("@angular/common/http");
var rxjs_1 = require("rxjs");
var email_validator_1 = require("../registration/validators/email.validator");
var password_validator_1 = require("../registration/validators/password.validator");
var RegistrationFormProvider = /** @class */ (function () {
    function RegistrationFormProvider(fb, http) {
        this.fb = fb;
        this.http = http;
        this.DOMAIN_REGEX = /^[a-z0-9]+([\-]{1}[a-z0-9]+)*$/;
        this.PHONE_REGEX = /^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/;
        this.WEBSITE_ADDRESS_REGEX = /(https?:\/\/[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/[a-zA-Z0-9]+\.[^\s]{2,}|[a-zA-Z0-9]+\.[^\s]{2,})/;
        this.submitingForm = new rxjs_1.BehaviorSubject(false);
        this.step1FormElements = ['firstName', 'lastName', 'email', 'password', 'phone'];
        this.step2FormElements = ['companyName', 'domain', 'country', 'timeZone', 'businessSector', 'website'];
        this.buildForm();
    }
    RegistrationFormProvider.prototype.stepValid = function (elements) {
        var _this = this;
        return elements.map(function (control) { return _this.registrationForm.get(control).valid; }).reduce(function (prev, curr) { return prev && curr; }, true);
    };
    RegistrationFormProvider.prototype.isStep1Valid = function () {
        return this.stepValid(this.step1FormElements);
    };
    RegistrationFormProvider.prototype.isStep2Valid = function () {
        return this.stepValid(this.step2FormElements);
    };
    RegistrationFormProvider.prototype.isFormValid = function () {
        return this.registrationForm.valid;
    };
    RegistrationFormProvider.prototype.disableForm = function () {
        this.registrationForm.disable({ emitEvent: false });
    };
    RegistrationFormProvider.prototype.enableForm = function () {
        this.registrationForm.enable({ emitEvent: false });
    };
    RegistrationFormProvider.prototype.markAsTouch = function (elements) {
        var _this = this;
        elements.forEach(function (elem) {
            _this.registrationForm.get(elem).markAsTouched();
            _this.registrationForm.get(elem).markAsDirty();
            _this.registrationForm.get(elem).updateValueAndValidity();
        });
    };
    RegistrationFormProvider.prototype.step1MarkAsTouched = function () {
        this.markAsTouch(this.step1FormElements);
    };
    RegistrationFormProvider.prototype.step2MarkAsTouched = function () {
        this.markAsTouch(this.step2FormElements);
    };
    Object.defineProperty(RegistrationFormProvider.prototype, "registrationForm", {
        get: function () {
            return this._registrationForm;
        },
        enumerable: true,
        configurable: true
    });
    RegistrationFormProvider.prototype.buildForm = function () {
        var _this = this;
        this._registrationForm = this.fb.group({
            firstName: ['', [forms_1.Validators.required]],
            lastName: ['', [forms_1.Validators.required]],
            email: ['', [email_validator_1.emailValidator, forms_1.Validators.required]],
            password: ['', [forms_1.Validators.required, password_validator_1.default]],
            phone: [
                '',
                [
                    forms_1.Validators.required,
                    function (fc) {
                        if (!fc.value.trim()) {
                            return {
                                phoneInvalid: true
                            };
                        }
                        return _this.PHONE_REGEX.exec(fc.value) ? null : { phoneInvalid: true };
                    }
                ]
            ],
            companyName: ['', [forms_1.Validators.required, forms_1.Validators.maxLength(60)]],
            domain: ['', [forms_1.Validators.required, forms_1.Validators.pattern(this.DOMAIN_REGEX), forms_1.Validators.maxLength(59)]],
            country: ['', forms_1.Validators.required],
            timeZone: ['', forms_1.Validators.required],
            businessSector: ['', [forms_1.Validators.required]],
            website: [
                '',
                [
                    forms_1.Validators.required,
                    function (control) {
                        if (forms_1.Validators.pattern(_this.WEBSITE_ADDRESS_REGEX)(control)) {
                            return {
                                incorrectUrl: true
                            };
                        }
                        return null;
                    }
                ]
            ],
            saleCurrency: ['']
        });
    };
    return RegistrationFormProvider;
}());
exports.RegistrationFormProvider = RegistrationFormProvider;
