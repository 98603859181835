import { ParamMap } from '@angular/router';

export class VcMap<K, V> {
  private readonly _dataHolder: Map<K, V>;

  static fromRouteParamMap(paramMap: ParamMap): VcMap<string, string> {
    const vcMap = new VcMap<string, string>();
    paramMap.keys.forEach(key => {
      vcMap.set(key, paramMap.get(key));
    });
    return vcMap;
  }

  constructor() {
    this._dataHolder = new Map<K, V>();
  }

  toMap(): Map<K, V> {
    return this._dataHolder;
  }

  set(key: K, value: V): Map<K, V> {
    return this._dataHolder.set(key, value);
  }

  get(key: K): V {
    return this._dataHolder.get(key);
  }

  hasAnyValue(): boolean {
    return this._dataHolder.size > 0;
  }

  forEach(callback: (value: V, key: K) => void): void {
    this._dataHolder.forEach((value, key) => {
      callback(value, key);
    });
  }
}
