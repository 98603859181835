import { Injectable } from '@angular/core';
import { Locale } from '../shared/Locale.enum';
import { TranslateService } from '@ngx-translate/core';
import { map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
const cookieService = require('cookie');
@Injectable({
  providedIn: 'root'
})
export class ApplicationService {
  tipCount = 3;

  constructor(private translationService: TranslateService) {}

  getLocale() {
    return this.getLocaleFromCookie() || this.getLocaleFromBrowser() || this.getDefaultLocale();
  }
  getDefaultLocale() {
    return Locale.DEFAULT;
  }
  getLocaleFromCookie() {
    return this.parseCookie().dashboardLocale;
  }
  getLocaleFromBrowser() {
    return window.navigator.language.split('-')[0];
  }
  private parseCookie() {
    return cookieService.parse(document.cookie);
  }

  getToolTipsTexts(tipCount?: number): string[] {
    return Array(tipCount || this.tipCount)
      .fill('tip', 0, this.tipCount)
      .map((elem, index) => `tips.${elem}${index}`);
    // return Array(this.tipCount).map((elem, index) => `tips.tip${index}`);
  }
}
