"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".validation-tips-container[_ngcontent-%COMP%]{position:relative;margin-top:-.25rem}.validation-tips-list[_ngcontent-%COMP%]{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;gap:2px;font-size:14px}.validation-tips-blot[_ngcontent-%COMP%]{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:4px;color:#6c757d;-webkit-transition:.3s ease-in-out;transition:all .3s ease-in-out}.validation-tips-blot[_ngcontent-%COMP%]   far[_ngcontent-%COMP%]{color:#343a40}.is-open[_ngcontent-%COMP%]{display:-webkit-box;display:-ms-flexbox;display:flex}.active-validation-blot[_ngcontent-%COMP%]{color:#000}.active-validation-blot[_ngcontent-%COMP%]   i.far[_ngcontent-%COMP%]{color:#fd7e14}.password-tips-loader[_ngcontent-%COMP%]{width:100%;height:4px;-webkit-transition:.2s;transition:all .2s ease;border-radius:1rem;background:rgba(0,0,0,.1);margin-bottom:.75rem;position:relative}.password-tips-loader__thumb[_ngcontent-%COMP%]{-webkit-transition:.2s;transition:all .2s ease;position:absolute;border-radius:1rem;left:0;top:0;height:100%;background:#fd7e14;width:0}"];
exports.styles = styles;
