import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { RegistrationFormProvider } from '../../services/registration-form.provider';
import { RegistrationProvider } from '../../services/registration.provider';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Subscription } from 'rxjs';
import { LegalContentLinksService } from '../../services/legal-content-links.service';
import { debounceTime } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Store } from '@ngrx/store';
import * as fromApp from '../../store/reducers/app.reducer';

@Component({
  selector: 'app-step2',
  templateUrl: './step2.component.html',
  styleUrls: ['./step2.component.less'],
  animations: []
})
export class Step2Component implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('country') firstFocusElement: ElementRef;

  private timeZones: { [prop: string]: { key: string; value: string }[] };
  private _allZone: { key: string; value: string }[];
  private impactTrackingDataSubscription: Subscription;
  private impactTrackingDataIrpid?: string;
  private impactTrackingDataIrclickid?: string;
  public countryList: { code: string; name: string; defaultCurrency: string }[];
  public currencies: string[];
  public registrationForm: FormGroup;
  public submitingForm: BehaviorSubject<boolean>;
  public responseErrors: Object;
  public redirectUrl: string;
  termsURL: string;
  cookiePolicyURL: string;
  showTimezoneSelect = true;
  countryFocus = false;
  timeZoneFocus = false;
  businessFocus = false;
  is500ErrorVisible = false;

  private readonly SALES_PAGE_ERROR = 'sale page';
  private readonly EMAIL_ERROR = 'email';
  private readonly COUNTRY_ERROR = 'country';

  get isCountryError() {
    return this.registrationFormService.registrationForm.controls.country.hasError('countryInvalid');
  }

  get isSectorError() {
    return (
      this.registrationFormService.registrationForm.controls.businessSector.touched &&
      this.registrationFormService.registrationForm.controls.businessSector.hasError('required')
    );
  }

  show500Error() {
    this.is500ErrorVisible = true;
  }

  hide500Error() {
    this.is500ErrorVisible = false;
  }

  get allZone(): { key: string; value: string }[] {
    if (this._allZone) {
      return this._allZone;
    } // tslint:disable-line
    let result = [];
    if (this.timeZones) {
      Object.entries(this.timeZones).map(([okey, ovalue]) => (result = [...result, ...ovalue]));
    }
    result.sort((a, b) => (a.key < b.key ? -1 : a.key > b.key ? 1 : 0));
    result.length > 0 ? (this._allZone = result) : null; // tslint:disable-line

    return this._allZone;
  }

  constructor(
    private registrationFormService: RegistrationFormProvider,
    private registrationService: RegistrationProvider,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private legalContentLinksService: LegalContentLinksService,
    private store: Store<fromApp.AppState>
  ) {
    this.registrationForm = registrationFormService.registrationForm;
    this.submitingForm = this.registrationFormService.submitingForm;
    this.termsURL = legalContentLinksService.getTermsUrl();
    this.cookiePolicyURL = legalContentLinksService.getCookiePolicyUrl();
  }

  ngOnInit() {
    this.updateAvailableTimeZonesOnCountryChange();
    this.updateSaleCurrencyOnCountryChange();
    const { formData } = this.activeRoute.snapshot.data;
    this.countryList = formData[0];
    this.timeZones = formData[1];
    this.currencies = formData[2];
    this.preselectCountry();
    this.registrationForm
      .get('companyName')
      .valueChanges.pipe(debounceTime(300))
      .subscribe(value => {
        this.setDataLayerVar('companyName', value);
      });
    this.registrationForm
      .get('domain')
      .valueChanges.pipe(debounceTime(300))
      .subscribe(value => {
        this.setDataLayerVar('salesUrl', value);
      });
    this.impactTrackingDataSubscription = this.store.select('impactTrackingData').subscribe(stateData => {
      const { irpid, irclickid } = stateData;
      this.impactTrackingDataIrpid = irpid;
      this.impactTrackingDataIrclickid = irclickid;
    });
  }

  ngOnDestroy() {
    this.impactTrackingDataSubscription.unsubscribe();
  }

  submitForm() {
    if (!this.registrationFormService.isFormValid()) {
      this.registrationFormService.step2MarkAsTouched();
      return;
    }
    this.registrationService.formWasSubmited = true;
    this.submitingForm.next(true);
    this.disableForm();
    this.registrationService.appendExtraData({
      irpid: this.impactTrackingDataIrpid,
      irclickid: this.impactTrackingDataIrclickid
    });
    this.registrationService
      .submit()
      .then((redirect: string) => {
        this.redirectSuccess(redirect);
      })
      .catch((e: HttpErrorResponse) => {
        this.enableForm();
        this.submitingForm.next(false);
        this.responseErrors = this.dealWithError(e);
      });
  }

  submit() {
    setTimeout(() => this.submitForm(), 100);
  }

  hasError(controlName: string) {
    return this.registrationForm.get(controlName).touched && this.registrationForm.get(controlName).invalid;
  }

  private updateAvailableTimeZonesOnCountryChange() {
    this.registrationForm.controls.country.valueChanges.subscribe(this.updateTimezones);
  }

  private updateTimezones = countryCode => {
    const timeZones = this.timeZones[countryCode];
    if (!timeZones) {
      this.showTimezoneSelect = true;
      this._allZone = null;
      return;
    }
    this.registrationForm.controls.timeZone.patchValue(timeZones[0].key);
    if (timeZones.length > 1) {
      this.showTimezoneSelect = true;
      this._allZone = this.timeZones[countryCode];
    } else {
      this.showTimezoneSelect = false;
    }
  };

  private updateSaleCurrencyOnCountryChange() {
    this.registrationForm.controls.country.valueChanges.subscribe(value => {
      const country = this.countryList.find(c => c.code.toLowerCase() === value.toLowerCase());
      if (this.currencies.includes(country.defaultCurrency)) {
        this.registrationForm.controls.saleCurrency.patchValue(country.defaultCurrency);
      } else {
        this.registrationForm.controls.saleCurrency.patchValue('USD');
      }
    });
  }

  private disableForm() {
    this.registrationFormService.disableForm();
  }

  private enableForm() {
    this.registrationFormService.enableForm();
  }

  private preselectCountry() {
    let countryCode: string;
    if (this.registrationForm.get('country').value) {
      countryCode = this.registrationForm.get('country').value;
    } else if (window.navigator.language.length === 2) {
      countryCode = window.navigator.language.toLowerCase();
    } else if (window.navigator.language.slice(3).length === 2) {
      countryCode = window.navigator.language.slice(3).toLowerCase();
    }
    if (countryCode) {
      const indexInJson = Array.prototype.findIndex.call(this.countryList, it => it.code.toLowerCase() === countryCode);
      if (indexInJson > -1) {
        this.registrationFormService.registrationForm.patchValue({ country: countryCode }, { emitEvent: true }); // tslint:disable-line
        this.registrationForm.get('country').markAsDirty();
        this.registrationForm.get('country').markAsTouched();
      }
    }
  }

  private dealWithError(e: HttpErrorResponse): string {
    try {
      if (e.status === 500) {
        this.show500Error();
      }
      const errObj = e.error.errors;
      const errMsg: string = errObj[Object.keys(errObj)[0]][0];
      if (errMsg.toLowerCase().indexOf(this.SALES_PAGE_ERROR) !== -1) {
        this.registrationService.domainNotAviable = true;
        this.registrationForm.get('domain').setErrors({ domainExist: true });
      }
      if (errMsg.toLowerCase().indexOf(this.EMAIL_ERROR) !== -1) {
        this.registrationFormService.registrationForm.controls.email.setErrors({
          emailInvalid: true
        });
      }
      if (errMsg.toLowerCase().indexOf(this.COUNTRY_ERROR) !== -1) {
        this.registrationFormService.registrationForm.controls.country.setErrors({ countryInvalid: true });
      }
      return errMsg;
    } catch (handleError) {
      let redirect = '';
      switch (environment.ENV_NAME) {
        default:
        case 'production':
          redirect = `${this.registrationFormService.registrationForm.controls.domain.value}.vouchercart.com/staff/sign-in`;
          break;
        case 'staging':
          redirect = `${this.registrationFormService.registrationForm.controls.domain.value}.stagingvouchercart.com/staff/sign-in`;
          break;
        case 'local-testing':
          redirect = `${this.registrationFormService.registrationForm.controls.domain.value}.vouchercart.localhost/staff/sign-in`;
          break;
      }
      this.redirectSuccess(redirect);
      return '';
    }
  }

  focus(focus: string) {
    this[focus] = true;
  }

  blur(focus: string) {
    this[focus] = false;
  }

  ngAfterViewInit(): void {
    setTimeout(() => document.getElementById('businessSector').focus(), 300);
  }

  boBack() {
    this.router.navigate(['..', 'step1'], {
      relativeTo: this.activeRoute,
      skipLocationChange: false
    });
  }

  private setDataLayerVar(varName: string, value: string): void {
    (window['dataLayer'] as any[]).push({
      [varName]: value
    });
  }

  private redirectSuccess(redirect: string): void {
    window.location.href = `${window.location.origin}/registration/thankyou?url=${redirect}`;
  }
}
