import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { RegistrationFormProvider } from '../../../services/registration-form.provider';
import { Subscription } from 'rxjs';
import { AbstractControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-error-displayer',
  templateUrl: './error-displayer.component.html',
  styleUrls: ['./error-displayer.component.scss']
})
export class ErrorDisplayerComponent implements OnInit, OnDestroy {
  @Input() emRequired = 'Field is required';
  @Input() emPhoneInvalid = 'Phone is invalid';
  @Input() emMinLength = 'Minimal length is';
  @Input() emEmail = 'Email is not valid';
  @Input() emNonBusinessEmail = 'Please provide a business email address';
  @Input() emMax = 'This is to long, max length is';
  @Input() emMin: string;
  @Input() emDomainNotAviable: 'This url  is not aviable';
  @Input() emDomainIncorrect: 'This url is no correct';
  @Input() emDefault: 'Field has error';
  @Input() emDomainExist: 'Field has error';
  @Input() emNoDomainForEmailError: 'There is no registered domain for this email';
  @Input() emIncorrectUrl = 'This url is incorrect';
  @Input() appFieldName: string;
  @Input() form: FormGroup;
  private field: AbstractControl;

  public get errors(): string[] {
    if (!this.field) {
      return;
    }
    if (this.isEmptyObject(this.field.errors)) {
      return [];
    } // tslint:disable-line
    return Object.entries(this.field.errors).map(([errorCode, errorObject]) => {
      if (errorCode === null) {
        return null;
      }
      switch (errorCode) {
        case 'noDomainForEmailError':
          return this.emNoDomainForEmailError; /*  */
        case 'email':
          return this.emEmail; /* email:true */
        case 'required' /* require */:
          return this.emRequired;
        case 'phoneInvalid' /* phoneInvalid */:
          return this.emPhoneInvalid;
        case 'minlength' /*{'minlength': {'requiredLength': minLength, 'actualLength': length}} :*/:
          return `${this.emMin} (Currently ${errorObject.actualLength})`;
        case 'maxlength':
          return (
            this.emMax || `Company name length is to long, should be max 60 characters long`
          ); /* {'max': {'max': max, 'actual': control.value}} */
        case 'domainNotAviable':
          return this.emDomainNotAviable;
        case 'domainIncorrect':
          return this.emDomainIncorrect;
        case 'pattern':
          return this.emDomainIncorrect;
        case 'domainExist':
          return '';
        case 'nonBusinessEmail':
          return this.emNonBusinessEmail;
        case 'incorrectUrl':
          return this.emIncorrectUrl;
        default:
          return 'Field Error';
      }
    });
  }

  constructor(private formProvider: RegistrationFormProvider) {}

  ngOnInit() {
    this.form = this.form || this.formProvider.registrationForm;
    this.field = this.form.get(this.appFieldName);
  }

  private isEmptyObject(obj: any) {
    for (const k in obj) {
      if (obj.hasOwnProperty(k)) {
        return false;
      }
    }
    return true;
  }

  get show() {
    const { touched, invalid, dirty } = this.field;
    return touched && invalid;
  }

  ngOnDestroy(): void {}
}
