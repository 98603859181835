"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".err-code[_ngcontent-%COMP%]{font-size:350px;color:rgba(108,117,125,.86)}@media (max-width:600px){.err-code[_ngcontent-%COMP%]{font-size:250px}}@media (max-width:500px){.err-code[_ngcontent-%COMP%]{font-size:180px}}@media (max-width:300px){.err-code[_ngcontent-%COMP%]{font-size:100px}}.box[_ngcontent-%COMP%]{background-color:#fff;margin:auto;border:1px solid #e8e8e8;padding:20px 60px 60px;-webkit-box-shadow:0 10px 25px #e8e8e8;box-shadow:0 10px 25px #e8e8e8}"];
exports.styles = styles;
