import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment as ENV } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private BASE_URL = ENV.API_ENDPOINT;
  private APP_URL = ENV.APP_URL;
  private DOMAINS_URL = 'user-domains';
  private AUTHORIZE = 'authorize';

  constructor(private http: HttpClient) {}

  getDomainsForEmail(clientEmail: string): Observable<Array<Domain>> {
    const email = encodeURIComponent(clientEmail);
    return this.http.get<DomainsResponse>(`${this.BASE_URL}/${this.DOMAINS_URL}?email=${email}`).pipe(
      tap(console.log),
      map(resp => resp.domains)
    );
  }

  login({ email, password, subdomain }): Observable<AuthorizationResponse> {
    const authHeader: string = btoa(email.concat(':', password));
    const headers = { Authorization: `Basic ${authHeader}` };
    return this.http
      .post<AuthorizationResponse>(`${this.BASE_URL}/${this.AUTHORIZE}`, { subdomain }, { headers })
      .pipe();
  }

  goToDashboardWithJwtToken(jwtToken: string) {
    window.location.href = `${this.APP_URL}/auth-jwt-token?accessToken=${jwtToken}`;
  }
}

export interface DomainsResponse {
  status: string;
  domains: Domain[];
}

export interface Domain {
  id: number;
  name: string;
  url: string;
}
export interface AuthorizationResponse {
  accessToken: string;
  profile: {
    email: string;
    fullName: string;
    id: number;
    locale: string;
  };
  refreshToken: string;
}
