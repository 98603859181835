"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Locale_enum_1 = require("../shared/Locale.enum");
var core_1 = require("@ngx-translate/core");
var i0 = require("@angular/core");
var i1 = require("@ngx-translate/core");
var cookieService = require('cookie');
var ApplicationService = /** @class */ (function () {
    function ApplicationService(translationService) {
        this.translationService = translationService;
        this.tipCount = 3;
    }
    ApplicationService.prototype.getLocale = function () {
        return this.getLocaleFromCookie() || this.getLocaleFromBrowser() || this.getDefaultLocale();
    };
    ApplicationService.prototype.getDefaultLocale = function () {
        return Locale_enum_1.Locale.DEFAULT;
    };
    ApplicationService.prototype.getLocaleFromCookie = function () {
        return this.parseCookie().dashboardLocale;
    };
    ApplicationService.prototype.getLocaleFromBrowser = function () {
        return window.navigator.language.split('-')[0];
    };
    ApplicationService.prototype.parseCookie = function () {
        return cookieService.parse(document.cookie);
    };
    ApplicationService.prototype.getToolTipsTexts = function (tipCount) {
        return Array(tipCount || this.tipCount)
            .fill('tip', 0, this.tipCount)
            .map(function (elem, index) { return "tips." + elem + index; });
        // return Array(this.tipCount).map((elem, index) => `tips.tip${index}`);
    };
    ApplicationService.ngInjectableDef = i0.defineInjectable({ factory: function ApplicationService_Factory() { return new ApplicationService(i0.inject(i1.TranslateService)); }, token: ApplicationService, providedIn: "root" });
    return ApplicationService;
}());
exports.ApplicationService = ApplicationService;
