"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var environment_1 = require("../../environments/environment");
var core_1 = require("@ngx-translate/core");
var i0 = require("@angular/core");
var i1 = require("@ngx-translate/core");
var LegalContentLinksService = /** @class */ (function () {
    function LegalContentLinksService(translationService) {
        this.translationService = translationService;
        this.linkUrl = environment_1.environment.MAIN_WEBSITE_URL;
    }
    LegalContentLinksService.prototype.getTermsUrl = function () {
        return this.linkUrl + "/" + this.translationService.currentLang + "/merchant-terms-and-conditions";
    };
    LegalContentLinksService.prototype.getCookiePolicyUrl = function () {
        return this.linkUrl + "/" + this.translationService.currentLang + "/cookie-policy/";
    };
    LegalContentLinksService.prototype.getContactUrl = function () {
        return this.linkUrl + "/" + this.translationService.currentLang + "/contact/";
    };
    LegalContentLinksService.ngInjectableDef = i0.defineInjectable({ factory: function LegalContentLinksService_Factory() { return new LegalContentLinksService(i0.inject(i1.TranslateService)); }, token: LegalContentLinksService, providedIn: "root" });
    return LegalContentLinksService;
}());
exports.LegalContentLinksService = LegalContentLinksService;
