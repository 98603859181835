"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./thankyou.component.less.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("@ngx-translate/core");
var i3 = require("./thankyou.component");
var i4 = require("@angular/router");
var styles_ThankyouComponent = [i0.styles];
var RenderType_ThankyouComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ThankyouComponent, data: {} });
exports.RenderType_ThankyouComponent = RenderType_ThankyouComponent;
function View_ThankyouComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "section", [["class", "d-flex flex-column flex-grow-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "p", [["class", "text-center title mb-1"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 2, "p", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("setupCompleted")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("takeToDashboard")); _ck(_v, 5, 0, currVal_1); }); }
exports.View_ThankyouComponent_0 = View_ThankyouComponent_0;
function View_ThankyouComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-thankyou", [], null, null, null, View_ThankyouComponent_0, RenderType_ThankyouComponent)), i1.ɵdid(1, 114688, null, 0, i3.ThankyouComponent, [i4.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_ThankyouComponent_Host_0 = View_ThankyouComponent_Host_0;
var ThankyouComponentNgFactory = i1.ɵccf("app-thankyou", i3.ThankyouComponent, View_ThankyouComponent_Host_0, {}, {}, []);
exports.ThankyouComponentNgFactory = ThankyouComponentNgFactory;
