"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var router_1 = require("@angular/router");
var http_loader_1 = require("@ngx-translate/http-loader");
var error404_component_1 = require("./error404/error404.component");
var GoogleAnalytics_service_1 = require("./services/analytics-clients/GoogleAnalytics.service");
var FacebookPixelAnalytics_service_1 = require("./services/analytics-clients/FacebookPixelAnalytics.service");
var ConsoleLogAnalytics_service_1 = require("./services/analytics-clients/ConsoleLogAnalytics.service");
var environment_1 = require("../environments/environment");
// AoT requires an exported function for factories
function createTranslateLoader(http) {
    return new http_loader_1.TranslateHttpLoader(http, './assets/i18n/', '.json');
}
exports.createTranslateLoader = createTranslateLoader;
exports.googleAnalyticsFactory = function (envName) { return function () {
    switch (envName) {
        case 'production':
        case 'staging':
            return new GoogleAnalytics_service_1.GoogleAnalyticsService();
        default:
            return new ConsoleLogAnalytics_service_1.ConsoleLogAnalyticsService();
    }
}; };
exports.facebookPixelAnalyticsFactory = function (production) { return function () {
    return production ? new FacebookPixelAnalytics_service_1.FacebookPixelAnalyticsService() : new ConsoleLogAnalytics_service_1.ConsoleLogAnalyticsService();
}; };
var routes = [
    {
        path: '',
        redirectTo: 'registration/step1',
        pathMatch: 'full'
    },
    {
        path: '**',
        component: error404_component_1.Error404Component
    }
];
var ɵ0 = exports.googleAnalyticsFactory(environment_1.environment.ENV_NAME), ɵ1 = exports.facebookPixelAnalyticsFactory(environment_1.environment.production), ɵ2 = environment_1.environment.GTM_ID;
exports.ɵ0 = ɵ0;
exports.ɵ1 = ɵ1;
exports.ɵ2 = ɵ2;
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
exports.AppModule = AppModule;
