"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var registration_form_provider_1 = require("../../services/registration-form.provider");
var registration_provider_1 = require("../../services/registration.provider");
var core_2 = require("@ngx-translate/core");
var operators_1 = require("rxjs/operators");
var vc_map_1 = require("../../util/vc-map");
var Step1Component = /** @class */ (function () {
    function Step1Component(applicationRef, router, activatedRoute, registrationProvider, registrationFormProvider, translation) {
        this.applicationRef = applicationRef;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.registrationProvider = registrationProvider;
        this.registrationFormProvider = registrationFormProvider;
        this.translation = translation;
        this.signinWithSocial = false;
        this.passwordInputFocused = false;
        this.registrationForm = registrationFormProvider.registrationForm;
    }
    Step1Component.prototype.ngOnInit = function () {
        var _this = this;
        this.translation
            .get('placeholders')
            .pipe(operators_1.take(1))
            .subscribe(function (data) { return (_this.placholders = data); });
        this.initRouteInfo();
    };
    Step1Component.prototype.ngAfterViewInit = function () {
        setTimeout(function () { return document.querySelector('[name="firstName"]').focus(); }, 300);
    };
    Step1Component.prototype.page1Valid = function () {
        return this.registrationFormProvider.isStep1Valid();
    };
    Step1Component.prototype.showValidationErrors = function () {
        this.registrationFormProvider.step1MarkAsTouched();
    };
    Step1Component.prototype.goToStep2 = function () {
        if (!this.page1Valid()) {
            this.showValidationErrors();
        }
        else {
            this.router.navigate(['..', 'step2'], {
                relativeTo: this.activatedRoute
            });
        }
    };
    Step1Component.prototype.initRouteInfo = function () {
        var routeParams = vc_map_1.VcMap.fromRouteParamMap(this.activatedRoute.snapshot.queryParamMap);
        if (routeParams.hasAnyValue()) {
            this.updateForm(routeParams);
            this.setExtraData(routeParams);
        }
    };
    Step1Component.prototype.updateForm = function (routeParams) {
        var _this = this;
        routeParams.forEach(function (value, key) {
            switch (key) {
                case 'country':
                    _this.registrationForm.get(key).setValue(value.toLowerCase());
                    break;
                default:
                    if (_this.registrationForm.get(key)) {
                        _this.registrationForm.get(key).setValue(value);
                    }
                    break;
            }
        });
    };
    Step1Component.prototype.setExtraData = function (routeParams) {
        var integrationName = routeParams.get('extra_data[integration][name]');
        var integrationAccessToken = routeParams.get('extra_data[integration][configuration][accessToken]');
        if (integrationName && integrationAccessToken) {
            this.registrationProvider.mewsExtraData = {
                integration: {
                    name: integrationName,
                    configuration: {
                        accessToken: integrationAccessToken
                    }
                }
            };
        }
    };
    Object.defineProperty(Step1Component.prototype, "validationTipsErrors", {
        get: function () {
            var validators = {
                numbers: false,
                mixedCase: false,
                specialChars: false,
                length: false
            };
            return tslib_1.__assign({}, validators, this.registrationForm.controls['password'].errors);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Step1Component.prototype, "isPasswordTipsOpen", {
        get: function () {
            if (this.passwordInputFocused) {
                return true;
            }
            // if (!!this.registrationForm.controls['password'].value) {
            //   return true;
            // }
            return this.registrationForm.controls['password'].invalid && this.registrationForm.controls['password'].touched;
        },
        enumerable: true,
        configurable: true
    });
    return Step1Component;
}());
exports.Step1Component = Step1Component;
