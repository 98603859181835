"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var VcMap = /** @class */ (function () {
    function VcMap() {
        this._dataHolder = new Map();
    }
    VcMap.fromRouteParamMap = function (paramMap) {
        var vcMap = new VcMap();
        paramMap.keys.forEach(function (key) {
            vcMap.set(key, paramMap.get(key));
        });
        return vcMap;
    };
    VcMap.prototype.toMap = function () {
        return this._dataHolder;
    };
    VcMap.prototype.set = function (key, value) {
        return this._dataHolder.set(key, value);
    };
    VcMap.prototype.get = function (key) {
        return this._dataHolder.get(key);
    };
    VcMap.prototype.hasAnyValue = function () {
        return this._dataHolder.size > 0;
    };
    VcMap.prototype.forEach = function (callback) {
        this._dataHolder.forEach(function (value, key) {
            callback(value, key);
        });
    };
    return VcMap;
}());
exports.VcMap = VcMap;
