"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var PasswordValidationTipsComponent = /** @class */ (function () {
    function PasswordValidationTipsComponent() {
    }
    PasswordValidationTipsComponent.prototype.isValid = function (field) {
        if (!this.active) {
            return false;
        }
        return !this.validations[field];
    };
    Object.defineProperty(PasswordValidationTipsComponent.prototype, "loaderWidth", {
        get: function () {
            if (!this.active) {
                return 0;
            }
            var validatorValues = Object.values(this.validations);
            var countPassed = validatorValues.reduce(function (a, v) { return (!v ? a + 1 : a); }, 0);
            var percentPassed = Math.round((100 / validatorValues.length) * countPassed);
            return percentPassed + "%";
        },
        enumerable: true,
        configurable: true
    });
    PasswordValidationTipsComponent.prototype.ngOnInit = function () { };
    return PasswordValidationTipsComponent;
}());
exports.PasswordValidationTipsComponent = PasswordValidationTipsComponent;
