"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["input[_ngcontent-%COMP%]{-webkit-autofill:#99cad0}i[data-title][_ngcontent-%COMP%]:hover:after{font-family:sans-serif;text-transform:none!important;line-height:150%;letter-spacing:.19px;font-size:12px;padding:12px;z-index:1090;content:attr(data-title);border-radius:4px;color:#fff;background-color:#e67e22;position:absolute}.ovflow[_ngcontent-%COMP%]{overflow:visible}.as-input-group[_ngcontent-%COMP%]{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-ms-flex-flow:row;flex-flow:row;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.as-input-group[_ngcontent-%COMP%]   span[_ngcontent-%COMP%]{-webkit-box-flex:1;-ms-flex:1 1;flex:1 1;font-family:Arial,sans-serif;font-size:14px;margin-left:10px}.has-error[_ngcontent-%COMP%]   .input-wrapper[_ngcontent-%COMP%]   .form-control[_ngcontent-%COMP%]{background-color:rgba(245,87,83,.1)!important;border:1px solid rgba(0,0,0,.07);-webkit-box-shadow:none;box-shadow:none}.error-area[_ngcontent-%COMP%]{display:block}.error-area[_ngcontent-%COMP%]   p[_ngcontent-%COMP%]{font-size:13px;margin-bottom:2px}"];
exports.styles = styles;
