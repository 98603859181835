"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var route_change_listner_service_1 = require("./route-change.listner.service");
var analyticsClient_interface_1 = require("./analytics-clients/analyticsClient.interface");
var operators_1 = require("rxjs/operators");
var i0 = require("@angular/core");
var i1 = require("./route-change.listner.service");
var i2 = require("./analytics-clients/AnalyticsClientsInjectionToken");
var AnalyticsService = /** @class */ (function () {
    function AnalyticsService(routerChange, analyticsClients) {
        var _this = this;
        this.routerChange = routerChange;
        this.analyticsClients = analyticsClients;
        this.callEventOnEachClient = function (methodName) { return function () {
            var params = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                params[_i] = arguments[_i];
            }
            _this.analyticsClients.forEach(function (client) { return client[methodName](params); });
        }; };
    }
    AnalyticsService.prototype.start = function () {
        this.routerChangeNotification();
    };
    AnalyticsService.prototype.routerChangeNotification = function () {
        this.routerChange.navigationEndUrl$
            .pipe(operators_1.distinctUntilChanged())
            .subscribe(this.callEventOnEachClient(analyticsClient_interface_1.AnalyticsEventNames.PAGE_VIEW_EVENT));
    };
    AnalyticsService.ngInjectableDef = i0.defineInjectable({ factory: function AnalyticsService_Factory() { return new AnalyticsService(i0.inject(i1.RouteChangeListnerService), i0.inject(i2.ANALYTICS_CLIENTS)); }, token: AnalyticsService, providedIn: "root" });
    return AnalyticsService;
}());
exports.AnalyticsService = AnalyticsService;
