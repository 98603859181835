import { Component, Injector, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationService } from './services/application.service';
import { Locale } from './shared/Locale.enum';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { RegistrationFormProvider } from './services/registration-form.provider';
import { RegistrationProvider } from './services/registration.provider';
import { RegistrationParams } from './registration/RegistrationParams';
import { Animations } from './shared/animations/route.animation';
import { FormControl } from '@angular/forms';
import { filter } from 'rxjs/operators';
import { AnalyticsService } from './services/analytics.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { environment } from '../environments/environment';
import { Meta } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { UpdateIrclickid, UpdateIrpid } from './store/actions/impact-tracking-data.actions';
import * as fromApp from './store/reducers/app.reducer';

const cookieService = require('cookie');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
  animations: [Animations.routerAnimation]
})
export class AppComponent implements OnInit {
  params: RegistrationParams = {};
  tooltips = this.applicationService.getToolTipsTexts();

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private registrationFormProvider: RegistrationFormProvider,
    private registrationProvider: RegistrationProvider,
    private applicationService: ApplicationService,
    private translate: TranslateService,
    private analytics: AnalyticsService,
    private readonly injector: Injector,
    private readonly metaService: Meta,
    private store: Store<fromApp.AppState>
  ) {
    this.registrationProvider.params = this.params;
    this.analytics.start();
    if (environment.production) {
      const gtmService = this.injector.get(GoogleTagManagerService);
      gtmService.addGtmToDom();
    }
    if (environment.ENV_NAME !== 'production') {
      this.metaService.addTag({
        name: 'robots',
        content: 'noindex'
      });
    }
  }

  ngOnInit(): void {
    this.initTranslationService();
    this.initFormFromParam();
    this.initImpactTrackingParams();
    this.activatedRoute.queryParams.pipe(filter(params => !!Object.keys(params).length)).subscribe(params => {
      this.params = { ...this.params, ...params };
      this.initTranslationService();
      this.initFormFromParam();
      this.registrationProvider.params = { ...params };
    });
  }
  private initFormFromParam() {
    this.initReseller();
    this.initLang();

    this.params.email && this.registrationFormProvider.registrationForm.patchValue({ email: this.params.email });
    this.params.country &&
      this.registrationFormProvider.registrationForm.patchValue({ country: this.params.country.toLowerCase() });
    this.params.lang &&
      this.registrationFormProvider.registrationForm.addControl('lang', new FormControl(this.params.lang));
  }
  private initTranslationService() {
    this.translate.setDefaultLang(Locale.DEFAULT);
    this.translate.use(this.params.lang || this.applicationService.getLocale());
  }

  private initReseller() {
    const cookies = cookieService.parse(document.cookie);
    const resellerHash = cookies.campaign_broker || this.params.resellerHash;
    this.params.resellerHash = resellerHash;
    if (resellerHash) {
      this.registrationFormProvider.registrationForm.addControl('resellerHash', new FormControl(resellerHash));
    }
  }

  private initLang() {
    const cookies = cookieService.parse(document.cookie);
    this.params.lang =
      cookies.dashboardLocale || this.params.lang || this.applicationService.getLocale() || Locale.DEFAULT;
    this.registrationFormProvider.registrationForm.addControl('lang', new FormControl(this.params.lang));
  }

  private initImpactTrackingParams() {
    const cookies = cookieService.parse(document.cookie);
    const urlParams = new URLSearchParams(window.location.search);
    const irpid = urlParams.get('irpid') || cookies.irpid;
    const irclickid = urlParams.get('irclickid') || cookies.irclickid;

    if (irpid) {
      this.store.dispatch(new UpdateIrpid(irpid));
    }
    if (irclickid) {
      this.store.dispatch(new UpdateIrclickid(irclickid));
    }
  }

  getOutletAnimationTransitionName(o: RouterOutlet) {
    const name = o.isActivated && o.activatedRouteData && o.activatedRouteData.triggerName;
    return name;
  }
}
