"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UPDATE_IRPID = 'UPDATE_IRPID';
exports.UPDATE_IRCLICKID = 'UPDATE_IRCLICKID';
var UpdateIrpid = /** @class */ (function () {
    function UpdateIrpid(payload) {
        this.payload = payload;
        this.type = exports.UPDATE_IRPID;
    }
    return UpdateIrpid;
}());
exports.UpdateIrpid = UpdateIrpid;
var UpdateIrclickid = /** @class */ (function () {
    function UpdateIrclickid(payload) {
        this.payload = payload;
        this.type = exports.UPDATE_IRCLICKID;
    }
    return UpdateIrclickid;
}());
exports.UpdateIrclickid = UpdateIrclickid;
