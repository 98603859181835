import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RegistrationFormProvider } from '../../../services/registration-form.provider';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-company-name-domain-sync',
  templateUrl: './company-name-domain-sync.component.html',
  styleUrls: ['./company-name-domain-sync.component.css']
})
export class CompanyNameDomainSyncComponent implements OnInit {
  @Input() public domainExist: boolean;
  @Output() public onEnter = new EventEmitter();
  placeholders: any;
  public valueFromCompanyName: string;
  public syncOn = true;
  public domainFromCompanyName = '';
  public filterdValue: string;

  constructor(private formProvider: RegistrationFormProvider, private trans: TranslateService) {}

  ngOnInit() {
    this.trans
      .get('placeholders')
      .pipe(take(1))
      .subscribe(data => (this.placeholders = data));
  }

  private syncOff = () => (this.syncOn = false);

  onValueChangeCompanyName(value: string) {
    if (!this.syncOn) return; // tslint:disable-line
    this.domainFromCompanyName = this.parseDomain(value);
    value.length > 0 && this.validateDomain();
  }

  private parseDomain(domainString: string) {
    const value = domainString.trim();
    return value
      .replace(/\s+/g, '_dash_')
      .replace(/\W/g, '')
      .replace(/_dash_/g, '-');
  }

  onValueChangeDomain(value: string) {
    if (value !== this.domainFromCompanyName) this.syncOff(); // tslint:disable-line
  }

  validateDomain() {
    if (!this.syncOn) return;
    this.domainFromCompanyName = this.domainFromCompanyName.trim();
    this.domainFromCompanyName = this.domainFromCompanyName.replace(/-+$/, '').toLowerCase();
    this.formProvider.registrationForm.get('domain').patchValue(this.domainFromCompanyName, {});
    this.formProvider.registrationForm.get('domain').markAsDirty();
    this.formProvider.registrationForm.get('domain').markAsTouched();
    // this.formProvider.registrationForm.get('domain').updateValueAndValidity({emitEvent: true});
  }
}
