import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginPageComponent } from './login-page.component';
import { RegistrationModule } from '../registration/registration.module';
import { RouterModule } from '@angular/router';
import { LoginFormComponent } from './login-form/login-form.component';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { LOGIN_PAGE } from '../shared/animations/router-animations-transitions-names.enum';

@NgModule({
  declarations: [LoginPageComponent, LoginFormComponent],
  imports: [
    CommonModule,
    RegistrationModule,
    RouterModule.forChild([{ path: 'login', component: LoginPageComponent, data: { triggerName: LOGIN_PAGE } }]),
    TranslateModule.forChild(),
    ReactiveFormsModule
  ]
})
export class LoginPageModule {}
