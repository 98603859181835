"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.environment = {
    production: true,
    INTERCOM_APP_ID: 'lu7ds7rp',
    MAIN_WEBSITE_URL: 'https://stagingvouchercart.com',
    API_ENDPOINT: 'https://api.mirrorvouchercart.com/v1',
    API_ENDPOINT_V2: 'https://api.mirrorvouchercart.com/v2',
    APP_URL: 'https://app.mirrorvouchercart.com',
    SENTRY_DNS: 'https://113cc32470fc4120be8d934efaa84110@sentry.io/1524962',
    ENV_NAME: 'mirror',
    GA_TRACKING_ID: 'G-K0HJJGS3HF',
    GTM: 'GTM-NSQWKK',
    FACEBOOK_PIXEL: '1910447432538920',
    GTM_ID: 'G-K0HJJGS3HF'
};
