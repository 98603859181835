import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { RegistrationFormProvider } from '../../services/registration-form.provider';

@Injectable({
  providedIn: 'root'
})
export class CanOpenStep2Guard implements CanActivate {
  constructor(private registrationFormService: RegistrationFormProvider, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.registrationFormService.isStep1Valid()) {
      return true;
    } else {
      this.router.navigate(['registration/step1']);
    }
  }
}
