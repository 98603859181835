<p class="text-center text-uppercase title mb-1" i18n>
  {{'page1Header' | translate}}
</p>
<p class="subtitle">{{'page1subHeader' | translate}}</p>


<div class="mt-4 d-flex flex-grow-1"> <!--form_holder-->
  <form [formGroup]="registrationForm" class="d-flex flex-grow-1 flex-column">

    <app-vcinput myControlName="firstName"
                 [fieldRequire]="true"
                 type="text"
                 [placeholder]="placholders?.firstName"
                 i18n-label
                 label="labels.firstName"
                 (onEnter)="goToStep2()"
    ></app-vcinput>
    <app-vcinput myControlName="lastName"
                 [fieldRequire]="true"
                 type="text"
                 [placeholder]="placholders?.lastName"
                 i18n-label
                 label="labels.lastName"
                 (onEnter)="goToStep2()"
    ></app-vcinput>
    <app-vcinput
      [fieldRequire]="true"
      myControlName="email"
      inputType="email"
      [placeholder]="placholders?.email"
      label="labels.email"
      [value]="registrationForm.get('email').value"
      (onEnter)="goToStep2()"
    >

    </app-vcinput>
    <app-vcinput myControlName="phone"
                 [fieldRequire]="true"
                 type="text"
                 [placeholder]="placholders?.phone"
                 label="labels.phone"
                 (onEnter)="goToStep2()"

    ></app-vcinput>

    <app-vcinput
      class="custom-vc-input"
      *ngIf="!signinWithSocial"
      [hidden]="signinWithSocial"
      myControlName="password"
      [fieldRequire]="true"
      inputType="password"
      i18n-label
      label="labels.password"
      disableErrorHandling="true"
      [placeholder]="placholders?.password"
      (onEnter)="goToStep2()"
      (onBlur)="passwordInputFocused = false"
      (onFocus)="passwordInputFocused = true"
    ></app-vcinput>
    <app-password-validation-tips
      [validations]="validationTipsErrors"
      [active]="!!registrationForm.controls['password'].value"
      [open]="isPasswordTipsOpen"
    ></app-password-validation-tips>

    <div class="mt-auto">


      <div class="d-flex justify-content-end">
        <button *ngIf="page1Valid(); else page1Invalid" type="button"
                class="btn btn-lg btn-primary btn-block m-t-10"
                (click)="goToStep2()"
        >
          {{'labels.next' | translate}}
        </button>
        <ng-template #page1Invalid>
          <button
            class="btn btn-lg btn-default btn-block m-t-10"
            (click)="showValidationErrors()"
          >
            {{'labels.next' | translate}}
          </button>
        </ng-template>

      </div>

      <div class="footer-text">
        <span class="text-nowrap">{{'labels.alreadyHaveAccount' | translate}}</span> &nbsp;
        <a class="text-nowrap text-primary " [routerLink]="'/login'">{{'labels.signInHere' | translate}}</a>
      </div>
    </div>
  </form>
  <section hidden>
    <pre>{{registrationForm.value | json}}</pre>
  </section>
</div>

