"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var registration_form_provider_1 = require("../../../services/registration-form.provider");
var forms_1 = require("@angular/forms");
var core_2 = require("@ngx-translate/core");
var operators_1 = require("rxjs/operators");
var VcinputComponent = /** @class */ (function () {
    function VcinputComponent(registrationFormProvider, translation) {
        this.registrationFormProvider = registrationFormProvider;
        this.translation = translation;
        this.hideExtraText = false;
        this.hasFocus = false;
        this.inputType = 'text';
        this.onEnter = new core_1.EventEmitter();
        this.disableErrorHandling = false;
        this.valueChange = new core_1.EventEmitter();
        this.blurEE = new core_1.EventEmitter();
        this.onFocusEvent = new core_1.EventEmitter();
        this.showCustomText = true;
    }
    Object.defineProperty(VcinputComponent.prototype, "value", {
        set: function (value) {
            if (this.input) {
                this.input.nativeElement.value = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    VcinputComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.form = this.form || this.registrationFormProvider.registrationForm;
        this.controlObject = this.form.get(this.myControlName);
        this.translation
            .get('tooltips.domainTooltip')
            .pipe(operators_1.take(1))
            .subscribe(function (data) { return (_this.tooltipText = data); });
        this.translation
            .get('errors')
            .pipe(operators_1.take(1))
            .subscribe(function (errorsTranslation) { return (_this.errorsTranslations = errorsTranslation); });
    };
    VcinputComponent.prototype.onInputFocus = function () {
        this.hasFocus = true;
        this.onFocusEvent.emit();
    };
    VcinputComponent.prototype.onBlur = function (event) {
        this.hasFocus = false;
        if (event.target.value === '')
            this.clearField(); // tslint:disable-line
        this.blurEE.emit();
    };
    VcinputComponent.prototype.clearField = function () {
        this.controlObject.setValue('');
    };
    VcinputComponent.prototype.onKeyPress = function (event) {
        var target = event.target;
        this.valueChange.emit(target.value);
        if (event.code === 'Enter')
            target.blur(); // tslint:disable-line
    };
    VcinputComponent.prototype.showDomainExistError = function () {
        return this.myControlName === 'domain' && this.form.get('domain').hasError('domainExist');
    };
    VcinputComponent.prototype.hasError = function () {
        return this.form.get(this.myControlName).touched && this.form.get(this.myControlName).invalid;
    };
    return VcinputComponent;
}());
exports.VcinputComponent = VcinputComponent;
