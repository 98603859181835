"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var i0 = require("@angular/core");
var i1 = require("@angular/router");
var RouteChangeListnerService = /** @class */ (function () {
    function RouteChangeListnerService(router) {
        this.router = router;
        this.routeChange = new rxjs_1.ReplaySubject();
        this.navigationEndUrl$ = this.routeChange.asObservable();
        this.init();
    }
    RouteChangeListnerService.prototype.init = function () {
        this.router.events
            .pipe(operators_1.filter(function (event) { return event instanceof router_1.NavigationEnd; }), operators_1.map(function (event) { return event.urlAfterRedirects; }))
            .subscribe(this.routeChange);
    };
    RouteChangeListnerService.ngInjectableDef = i0.defineInjectable({ factory: function RouteChangeListnerService_Factory() { return new RouteChangeListnerService(i0.inject(i1.Router)); }, token: RouteChangeListnerService, providedIn: "root" });
    return RouteChangeListnerService;
}());
exports.RouteChangeListnerService = RouteChangeListnerService;
