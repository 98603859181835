import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { emailValidator } from '../registration/validators/email.validator';
import passwordValidator from '../registration/validators/password.validator';

@Injectable()
export class RegistrationFormProvider {
  private _registrationForm: FormGroup;
  private DOMAIN_REGEX = /^[a-z0-9]+([\-]{1}[a-z0-9]+)*$/;
  private PHONE_REGEX = /^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/;
  private WEBSITE_ADDRESS_REGEX = /(https?:\/\/[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/[a-zA-Z0-9]+\.[^\s]{2,}|[a-zA-Z0-9]+\.[^\s]{2,})/;
  public submitingForm = new BehaviorSubject(false);
  private step1FormElements = ['firstName', 'lastName', 'email', 'password', 'phone'];
  private step2FormElements = ['companyName', 'domain', 'country', 'timeZone', 'businessSector', 'website'];

  private stepValid(elements: string[]) {
    return elements.map(control => this.registrationForm.get(control).valid).reduce((prev, curr) => prev && curr, true);
  }

  public isStep1Valid() {
    return this.stepValid(this.step1FormElements);
  }

  public isStep2Valid() {
    return this.stepValid(this.step2FormElements);
  }
  public isFormValid() {
    return this.registrationForm.valid;
  }
  public disableForm() {
    this.registrationForm.disable({ emitEvent: false });
  }
  public enableForm() {
    this.registrationForm.enable({ emitEvent: false });
  }
  private markAsTouch(elements: string[]) {
    elements.forEach(elem => {
      this.registrationForm.get(elem).markAsTouched();
      this.registrationForm.get(elem).markAsDirty();
      this.registrationForm.get(elem).updateValueAndValidity();
    });
  }

  step1MarkAsTouched() {
    this.markAsTouch(this.step1FormElements);
  }

  step2MarkAsTouched() {
    this.markAsTouch(this.step2FormElements);
  }

  constructor(private fb: FormBuilder, private http: HttpClient) {
    this.buildForm();
  }

  get registrationForm(): FormGroup {
    return this._registrationForm;
  }

  private buildForm() {
    this._registrationForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [emailValidator, Validators.required]],
      password: ['', [Validators.required, passwordValidator]],
      phone: [
        '',
        [
          Validators.required,
          (fc: FormControl) => {
            if (!fc.value.trim()) {
              return {
                phoneInvalid: true
              };
            }
            return this.PHONE_REGEX.exec(fc.value) ? null : { phoneInvalid: true };
          }
        ]
      ],
      companyName: ['', [Validators.required, Validators.maxLength(60)]],
      domain: ['', [Validators.required, Validators.pattern(this.DOMAIN_REGEX), Validators.maxLength(59)]],
      country: ['', Validators.required],
      timeZone: ['', Validators.required],
      businessSector: ['', [Validators.required]],
      website: [
        '',
        [
          Validators.required,
          control => {
            if (Validators.pattern(this.WEBSITE_ADDRESS_REGEX)(control)) {
              return {
                incorrectUrl: true
              };
            }
            return null;
          }
        ]
      ],
      saleCurrency: ['']
    });
  }
}
