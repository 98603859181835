import { Injectable } from '@angular/core';
import { AnalyticsClient } from './analyticsClient.interface';

@Injectable()
export class GoogleAnalyticsService implements AnalyticsClient {
  constructor() {}

  pageViewEvent(url) {
    ga('set', 'page', url);
    ga('send', 'pageview');
  }
}
