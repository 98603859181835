import { Action } from '@ngrx/store';

export const UPDATE_IRPID = 'UPDATE_IRPID';
export const UPDATE_IRCLICKID = 'UPDATE_IRCLICKID';

export class UpdateIrpid implements Action {
  readonly type = UPDATE_IRPID;

  constructor(public payload: string) {}
}

export class UpdateIrclickid implements Action {
  readonly type = UPDATE_IRCLICKID;

  constructor(public payload: string) {}
}

export type ImpactTrackingDataAction = UpdateIrpid | UpdateIrclickid;
