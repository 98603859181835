import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { catchError, debounceTime, distinctUntilChanged, filter, map, startWith, switchMap, tap } from 'rxjs/operators';
import { Domain, LoginService } from '../../services/login.service';
import { of } from 'rxjs';
import { emailValidator } from '../../registration/validators/email.validator';
import { HttpErrorResponse } from '@angular/common/http';
import { LegalContentLinksService } from '../../services/legal-content-links.service';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.less']
})
export class LoginFormComponent implements OnInit, AfterViewInit {
  form: FormGroup;
  domainFocus: boolean;
  subdomainInvalid: boolean;
  searchingDomains: boolean;
  logging: boolean;
  contactURL: any;

  constructor(
    private fb: FormBuilder,
    private loginService: LoginService,
    private legalContent: LegalContentLinksService
  ) {
    this.contactURL = this.legalContent.getContactUrl();
    this.initForm();
  }

  ngOnInit() {
    this.onSetEmail();
  }

  private initForm() {
    this.form = this.fb.group({
      email: ['', [Validators.required, emailValidator]],
      password: ['', [Validators.required]],
      domains: this.fb.array([]),
      subdomain: ['', [Validators.required]],
      rememberMe: [true]
    });
  }

  private addDomains(domains: Domain[]) {
    this.subdomainInvalid = false;
    const domainsFormCotrolls = domains.map(domain => this.fb.control(domain));
    this.setDomains(domainsFormCotrolls);
    if (!domains.length) {
      return;
    }
    this.form.patchValue({ subdomain: domains[0].name });
  }

  getDomains(): FormArray {
    return <FormArray>this.form.get('domains');
  }

  setDomains(domains: FormControl[]) {
    this.form.setControl('domains', this.fb.array([...domains]));
  }

  onSetEmail() {
    const emailControl = this.form.get('email');
    emailControl.valueChanges
      .pipe(
        startWith(this.form.controls.email.value),
        filter(value => !emailControl.hasError('email') && !emailControl.hasError('require')),
        debounceTime(300),
        distinctUntilChanged(),
        tap(email => (this.searchingDomains = true)),
        switchMap(email =>
          this.loginService.getDomainsForEmail(email).pipe(
            catchError(err => {
              this.form.controls.email.setErrors({ noDomainForEmailError: true });
              return of([]);
            }),
            tap(_ => (this.searchingDomains = false))
          )
        )
      )
      .subscribe(domains => this.addDomains(domains));
  }

  login() {
    this.form.updateValueAndValidity();
    this.touchForm();
    if (this.form.valid) {
      this.logging = true;
      const { email, password, subdomain } = this.form.value;
      this.loginService
        .login({ email, password, subdomain })
        .pipe(map(response => response.accessToken))
        .subscribe(token => this.loginSuccess(token), error => this.showWrongCredentialsError(error));
    } else if (
      this.form.controls.subdomain.invalid &&
      this.form.controls.email.valid &&
      this.form.controls.password.valid
    ) {
      this.subdomainInvalid = true;
      this.showWrongCredentialsError();
    }
  }

  showWrongCredentialsError(error?: HttpErrorResponse) {
    this.logging = false;
    this.form.controls.email.setErrors({ subdomainInvalid: true });
    this.form.controls.password.setErrors({ subdomainInvalid: true });
  }

  onlyOneDomain() {
    return this.getDomains().length < 2;
  }

  touchForm() {
    ['email', 'password', 'subdomain'].forEach(controlName => {
      this.form.get(controlName).markAsTouched();
      this.form.get(controlName).updateValueAndValidity();
    });
  }

  fieldError(field: string, errorType?: string) {
    const { invalid, touched } = this.form.get(field);
    if (errorType) {
      return invalid && touched && this.form.get(field).hasError(errorType);
    }
    return invalid && touched;
  }

  private loginSuccess(token: string) {
    this.loginService.goToDashboardWithJwtToken(token);
  }
  ngAfterViewInit(): void {
    setTimeout(() => document.querySelector<HTMLInputElement>('[id="email"]').focus(), 300);
  }
}
