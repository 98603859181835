import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route } from '@angular/router';

@Component({
  selector: 'app-thankyou',
  templateUrl: './thankyou.component.html',
  styleUrls: ['./thankyou.component.less']
})
export class ThankyouComponent implements OnInit {
  constructor(private readonly activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    const url = this.activatedRoute.snapshot.queryParams.url;
    setTimeout(() => {
      window.location.href = url;
    }, 2000);
  }
}
