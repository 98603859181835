import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { RegistrationProvider } from '../services/registration.provider';
import { RegistrationFormProvider } from '../services/registration-form.provider';
import { Step1Component } from './step1/step1.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Step2Component } from './step2/step2.component';
import { ErrorDisplayerComponent } from './component/error-displayer/error-displayer.component';
import { VcinputComponent } from './component/vcinput/vcinput.component';
import { CompanyNameDomainSyncComponent } from './component/company-name-domain-sync/company-name-domain-sync.component';
import { SocialGoogleBtnComponent } from './component/social-google-btn/social-google-btn.component';
import { SocialFacebookBtnComponent } from './component/social-facebook-btn/social-facebook-btn.component';
import { VcselectComponent } from './component/vcselect/vcselect.component';
import { Step2Resolver } from './step2/step2.resolver';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../shared/shared.module';
import { PageHeaderComponent } from '../page-header/page-header.component';
import {
  REGISTRATION_PAGE,
  REGISTRATION_STEP1,
  REGISTRATION_STEP2
} from '../shared/animations/router-animations-transitions-names.enum';
import { CanOpenStep2Guard } from './step2/can-open-step2.guard';
import { Error500Component } from './component/error500/error500.component';
import { ThankyouComponent } from './thankyou/thankyou.component';
import { PasswordValidationTipsComponent } from './component/password-validation-tips/password-validation-tips.component';

const routes: Routes = [
  { path: 'registration', pathMatch: 'full', redirectTo: 'registration/step1' },
  { path: 'registration/step1', component: Step1Component, data: { triggerName: REGISTRATION_STEP1 } },
  {
    path: 'registration/step2',
    component: Step2Component,
    resolve: { formData: Step2Resolver },
    data: { triggerName: REGISTRATION_STEP2 },
    canActivate: [CanOpenStep2Guard]
  },
  {
    path: 'registration/thankyou',
    component: ThankyouComponent
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    SharedModule
  ],
  declarations: [
    Step1Component,
    Step2Component,
    PasswordValidationTipsComponent,
    ErrorDisplayerComponent,
    VcinputComponent,
    CompanyNameDomainSyncComponent,
    SocialGoogleBtnComponent,
    SocialFacebookBtnComponent,
    VcselectComponent,
    PageHeaderComponent,
    Error500Component,
    ThankyouComponent
  ],
  exports: [PageHeaderComponent],
  providers: [RegistrationProvider, RegistrationFormProvider, Step2Resolver /*AuthService*/]
})
export class RegistrationModule {}
