"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var registration_form_provider_1 = require("../../services/registration-form.provider");
var i0 = require("@angular/core");
var i1 = require("../../services/registration-form.provider");
var i2 = require("@angular/router");
var CanOpenStep2Guard = /** @class */ (function () {
    function CanOpenStep2Guard(registrationFormService, router) {
        this.registrationFormService = registrationFormService;
        this.router = router;
    }
    CanOpenStep2Guard.prototype.canActivate = function (next, state) {
        if (this.registrationFormService.isStep1Valid()) {
            return true;
        }
        else {
            this.router.navigate(['registration/step1']);
        }
    };
    CanOpenStep2Guard.ngInjectableDef = i0.defineInjectable({ factory: function CanOpenStep2Guard_Factory() { return new CanOpenStep2Guard(i0.inject(i1.RegistrationFormProvider), i0.inject(i2.Router)); }, token: CanOpenStep2Guard, providedIn: "root" });
    return CanOpenStep2Guard;
}());
exports.CanOpenStep2Guard = CanOpenStep2Guard;
