<div class="wrapper text-wrapper-carousel" (mouseenter)="pause()" (mouseleave)="unpause()">
    <p class="question">
      {{'didUKnow' | translate}}
    </p>
    <div class="text">
      <p [@textFlyAnimation]="shouldAnimate ? currentIndex : null" *ngFor="let text of tipsTexts; let i = index" class="mb-0">
        <span *ngIf="currentIndex === i">{{text | translate}}</span>
      </p>

    </div>


  <div class="action-row ml-0" >
    <div class="buttons ml-0">
      <button class="left ml-0" (click)="previous()"><i class="fal fa-angle-left"></i></button>
      <button class="right" (click)="next()"><i class="fal fa-angle-right"></i></button>
    </div>
    <div class="progressBar">
      <div class="progressBar2" [ngStyle]="{width: progressBarLength, transition: progress2TransitionWithStyle}"></div>
    </div>
    <div class="progress-text">
      <span class="current-index">{{currentIndex + 1}}</span>
      <span>/</span>
      <span class="total">{{total}}</span>
    </div>
  </div>
</div>
