"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var Sentry = require("@sentry/browser");
var environment_1 = require("../../environments/environment");
environment_1.environment.production &&
    Sentry.init({
        dsn: environment_1.environment.SENTRY_DNS,
        environment: environment_1.environment.ENV_NAME
    });
var SentryErrorHandler = /** @class */ (function () {
    function SentryErrorHandler() {
    }
    SentryErrorHandler.prototype.handleError = function (error) {
        environment_1.environment.production && Sentry.captureException(error.originalError || error);
        throw error;
    };
    return SentryErrorHandler;
}());
exports.SentryErrorHandler = SentryErrorHandler;
