"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var registration_form_provider_1 = require("../../services/registration-form.provider");
var registration_provider_1 = require("../../services/registration.provider");
var router_1 = require("@angular/router");
var legal_content_links_service_1 = require("../../services/legal-content-links.service");
var operators_1 = require("rxjs/operators");
var environment_1 = require("../../../environments/environment");
var store_1 = require("@ngrx/store");
var Step2Component = /** @class */ (function () {
    function Step2Component(registrationFormService, registrationService, activeRoute, router, legalContentLinksService, store) {
        var _this = this;
        this.registrationFormService = registrationFormService;
        this.registrationService = registrationService;
        this.activeRoute = activeRoute;
        this.router = router;
        this.legalContentLinksService = legalContentLinksService;
        this.store = store;
        this.showTimezoneSelect = true;
        this.countryFocus = false;
        this.timeZoneFocus = false;
        this.businessFocus = false;
        this.is500ErrorVisible = false;
        this.SALES_PAGE_ERROR = 'sale page';
        this.EMAIL_ERROR = 'email';
        this.COUNTRY_ERROR = 'country';
        this.updateTimezones = function (countryCode) {
            var timeZones = _this.timeZones[countryCode];
            if (!timeZones) {
                _this.showTimezoneSelect = true;
                _this._allZone = null;
                return;
            }
            _this.registrationForm.controls.timeZone.patchValue(timeZones[0].key);
            if (timeZones.length > 1) {
                _this.showTimezoneSelect = true;
                _this._allZone = _this.timeZones[countryCode];
            }
            else {
                _this.showTimezoneSelect = false;
            }
        };
        this.registrationForm = registrationFormService.registrationForm;
        this.submitingForm = this.registrationFormService.submitingForm;
        this.termsURL = legalContentLinksService.getTermsUrl();
        this.cookiePolicyURL = legalContentLinksService.getCookiePolicyUrl();
    }
    Object.defineProperty(Step2Component.prototype, "isCountryError", {
        get: function () {
            return this.registrationFormService.registrationForm.controls.country.hasError('countryInvalid');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Step2Component.prototype, "isSectorError", {
        get: function () {
            return (this.registrationFormService.registrationForm.controls.businessSector.touched &&
                this.registrationFormService.registrationForm.controls.businessSector.hasError('required'));
        },
        enumerable: true,
        configurable: true
    });
    Step2Component.prototype.show500Error = function () {
        this.is500ErrorVisible = true;
    };
    Step2Component.prototype.hide500Error = function () {
        this.is500ErrorVisible = false;
    };
    Object.defineProperty(Step2Component.prototype, "allZone", {
        get: function () {
            if (this._allZone) {
                return this._allZone;
            } // tslint:disable-line
            var result = [];
            if (this.timeZones) {
                Object.entries(this.timeZones).map(function (_a) {
                    var okey = _a[0], ovalue = _a[1];
                    return (result = result.concat(ovalue));
                });
            }
            result.sort(function (a, b) { return (a.key < b.key ? -1 : a.key > b.key ? 1 : 0); });
            result.length > 0 ? (this._allZone = result) : null; // tslint:disable-line
            return this._allZone;
        },
        enumerable: true,
        configurable: true
    });
    Step2Component.prototype.ngOnInit = function () {
        var _this = this;
        this.updateAvailableTimeZonesOnCountryChange();
        this.updateSaleCurrencyOnCountryChange();
        var formData = this.activeRoute.snapshot.data.formData;
        this.countryList = formData[0];
        this.timeZones = formData[1];
        this.currencies = formData[2];
        this.preselectCountry();
        this.registrationForm
            .get('companyName')
            .valueChanges.pipe(operators_1.debounceTime(300))
            .subscribe(function (value) {
            _this.setDataLayerVar('companyName', value);
        });
        this.registrationForm
            .get('domain')
            .valueChanges.pipe(operators_1.debounceTime(300))
            .subscribe(function (value) {
            _this.setDataLayerVar('salesUrl', value);
        });
        this.impactTrackingDataSubscription = this.store.select('impactTrackingData').subscribe(function (stateData) {
            var irpid = stateData.irpid, irclickid = stateData.irclickid;
            _this.impactTrackingDataIrpid = irpid;
            _this.impactTrackingDataIrclickid = irclickid;
        });
    };
    Step2Component.prototype.ngOnDestroy = function () {
        this.impactTrackingDataSubscription.unsubscribe();
    };
    Step2Component.prototype.submitForm = function () {
        var _this = this;
        if (!this.registrationFormService.isFormValid()) {
            this.registrationFormService.step2MarkAsTouched();
            return;
        }
        this.registrationService.formWasSubmited = true;
        this.submitingForm.next(true);
        this.disableForm();
        this.registrationService.appendExtraData({
            irpid: this.impactTrackingDataIrpid,
            irclickid: this.impactTrackingDataIrclickid
        });
        this.registrationService
            .submit()
            .then(function (redirect) {
            _this.redirectSuccess(redirect);
        })
            .catch(function (e) {
            _this.enableForm();
            _this.submitingForm.next(false);
            _this.responseErrors = _this.dealWithError(e);
        });
    };
    Step2Component.prototype.submit = function () {
        var _this = this;
        setTimeout(function () { return _this.submitForm(); }, 100);
    };
    Step2Component.prototype.hasError = function (controlName) {
        return this.registrationForm.get(controlName).touched && this.registrationForm.get(controlName).invalid;
    };
    Step2Component.prototype.updateAvailableTimeZonesOnCountryChange = function () {
        this.registrationForm.controls.country.valueChanges.subscribe(this.updateTimezones);
    };
    Step2Component.prototype.updateSaleCurrencyOnCountryChange = function () {
        var _this = this;
        this.registrationForm.controls.country.valueChanges.subscribe(function (value) {
            var country = _this.countryList.find(function (c) { return c.code.toLowerCase() === value.toLowerCase(); });
            if (_this.currencies.includes(country.defaultCurrency)) {
                _this.registrationForm.controls.saleCurrency.patchValue(country.defaultCurrency);
            }
            else {
                _this.registrationForm.controls.saleCurrency.patchValue('USD');
            }
        });
    };
    Step2Component.prototype.disableForm = function () {
        this.registrationFormService.disableForm();
    };
    Step2Component.prototype.enableForm = function () {
        this.registrationFormService.enableForm();
    };
    Step2Component.prototype.preselectCountry = function () {
        var countryCode;
        if (this.registrationForm.get('country').value) {
            countryCode = this.registrationForm.get('country').value;
        }
        else if (window.navigator.language.length === 2) {
            countryCode = window.navigator.language.toLowerCase();
        }
        else if (window.navigator.language.slice(3).length === 2) {
            countryCode = window.navigator.language.slice(3).toLowerCase();
        }
        if (countryCode) {
            var indexInJson = Array.prototype.findIndex.call(this.countryList, function (it) { return it.code.toLowerCase() === countryCode; });
            if (indexInJson > -1) {
                this.registrationFormService.registrationForm.patchValue({ country: countryCode }, { emitEvent: true }); // tslint:disable-line
                this.registrationForm.get('country').markAsDirty();
                this.registrationForm.get('country').markAsTouched();
            }
        }
    };
    Step2Component.prototype.dealWithError = function (e) {
        try {
            if (e.status === 500) {
                this.show500Error();
            }
            var errObj = e.error.errors;
            var errMsg = errObj[Object.keys(errObj)[0]][0];
            if (errMsg.toLowerCase().indexOf(this.SALES_PAGE_ERROR) !== -1) {
                this.registrationService.domainNotAviable = true;
                this.registrationForm.get('domain').setErrors({ domainExist: true });
            }
            if (errMsg.toLowerCase().indexOf(this.EMAIL_ERROR) !== -1) {
                this.registrationFormService.registrationForm.controls.email.setErrors({
                    emailInvalid: true
                });
            }
            if (errMsg.toLowerCase().indexOf(this.COUNTRY_ERROR) !== -1) {
                this.registrationFormService.registrationForm.controls.country.setErrors({ countryInvalid: true });
            }
            return errMsg;
        }
        catch (handleError) {
            var redirect = '';
            switch (environment_1.environment.ENV_NAME) {
                default:
                case 'production':
                    redirect = this.registrationFormService.registrationForm.controls.domain.value + ".vouchercart.com/staff/sign-in";
                    break;
                case 'staging':
                    redirect = this.registrationFormService.registrationForm.controls.domain.value + ".stagingvouchercart.com/staff/sign-in";
                    break;
                case 'local-testing':
                    redirect = this.registrationFormService.registrationForm.controls.domain.value + ".vouchercart.localhost/staff/sign-in";
                    break;
            }
            this.redirectSuccess(redirect);
            return '';
        }
    };
    Step2Component.prototype.focus = function (focus) {
        this[focus] = true;
    };
    Step2Component.prototype.blur = function (focus) {
        this[focus] = false;
    };
    Step2Component.prototype.ngAfterViewInit = function () {
        setTimeout(function () { return document.getElementById('businessSector').focus(); }, 300);
    };
    Step2Component.prototype.boBack = function () {
        this.router.navigate(['..', 'step1'], {
            relativeTo: this.activeRoute,
            skipLocationChange: false
        });
    };
    Step2Component.prototype.setDataLayerVar = function (varName, value) {
        var _a;
        window['dataLayer'].push((_a = {},
            _a[varName] = value,
            _a));
    };
    Step2Component.prototype.redirectSuccess = function (redirect) {
        window.location.href = window.location.origin + "/registration/thankyou?url=" + redirect;
    };
    return Step2Component;
}());
exports.Step2Component = Step2Component;
