import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit {
  mainWebsiteURL: string;
  envName: any;
  constructor() {
    this.mainWebsiteURL = environment.MAIN_WEBSITE_URL;
    this.envName = environment.ENV_NAME;
  }

  ngOnInit() {}
}
