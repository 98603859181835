import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { initializeGoogleAnalytics, facebookPixelInitialize, initilizeGoogleTagManager } from './analytics-scripts';
import { initializeIntercom } from './analytics-scripts/initializeIntercom';

if (environment.production) {
  enableProdMode();
  initializeGoogleAnalytics();
  initilizeGoogleTagManager();
  facebookPixelInitialize();
  initializeIntercom();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.log(err));
