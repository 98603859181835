"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var registration_form_provider_1 = require("../../../services/registration-form.provider");
var core_2 = require("@ngx-translate/core");
var operators_1 = require("rxjs/operators");
var CompanyNameDomainSyncComponent = /** @class */ (function () {
    function CompanyNameDomainSyncComponent(formProvider, trans) {
        var _this = this;
        this.formProvider = formProvider;
        this.trans = trans;
        this.onEnter = new core_1.EventEmitter();
        this.syncOn = true;
        this.domainFromCompanyName = '';
        this.syncOff = function () { return (_this.syncOn = false); };
    }
    CompanyNameDomainSyncComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.trans
            .get('placeholders')
            .pipe(operators_1.take(1))
            .subscribe(function (data) { return (_this.placeholders = data); });
    };
    CompanyNameDomainSyncComponent.prototype.onValueChangeCompanyName = function (value) {
        if (!this.syncOn)
            return; // tslint:disable-line
        this.domainFromCompanyName = this.parseDomain(value);
        value.length > 0 && this.validateDomain();
    };
    CompanyNameDomainSyncComponent.prototype.parseDomain = function (domainString) {
        var value = domainString.trim();
        return value
            .replace(/\s+/g, '_dash_')
            .replace(/\W/g, '')
            .replace(/_dash_/g, '-');
    };
    CompanyNameDomainSyncComponent.prototype.onValueChangeDomain = function (value) {
        if (value !== this.domainFromCompanyName)
            this.syncOff(); // tslint:disable-line
    };
    CompanyNameDomainSyncComponent.prototype.validateDomain = function () {
        if (!this.syncOn)
            return;
        this.domainFromCompanyName = this.domainFromCompanyName.trim();
        this.domainFromCompanyName = this.domainFromCompanyName.replace(/-+$/, '').toLowerCase();
        this.formProvider.registrationForm.get('domain').patchValue(this.domainFromCompanyName, {});
        this.formProvider.registrationForm.get('domain').markAsDirty();
        this.formProvider.registrationForm.get('domain').markAsTouched();
        // this.formProvider.registrationForm.get('domain').updateValueAndValidity({emitEvent: true});
    };
    return CompanyNameDomainSyncComponent;
}());
exports.CompanyNameDomainSyncComponent = CompanyNameDomainSyncComponent;
