import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-error500',
  templateUrl: './error500.component.html',
  styleUrls: ['./error500.component.scss']
})
export class Error500Component implements OnInit {
  @Output() closeClick = new EventEmitter();
  constructor() {}

  ngOnInit() {}
}
