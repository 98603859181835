import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { StoreModule } from '@ngrx/store';

import { AppComponent } from './app.component';
import { RegistrationModule } from './registration/registration.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Error404Component } from './error404/error404.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginPageModule } from './login-page/login-page.module';
import { TextCarouselComponent } from './text-carousel/text-carousel.component';
import { SentryErrorHandler } from './services/sentry-error-handler.service';
import { ANALYTICS_CLIENTS } from './services/analytics-clients/AnalyticsClientsInjectionToken';
import { GoogleAnalyticsService } from './services/analytics-clients/GoogleAnalytics.service';
import { FacebookPixelAnalyticsService } from './services/analytics-clients/FacebookPixelAnalytics.service';
import { ConsoleLogAnalyticsService } from './services/analytics-clients/ConsoleLogAnalytics.service';
import * as fromApp from './store/reducers/app.reducer';
import { environment } from '../environments/environment';

// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
export const googleAnalyticsFactory = envName => () => {
  switch (envName) {
    case 'production':
    case 'staging':
      return new GoogleAnalyticsService();
    default:
      return new ConsoleLogAnalyticsService();
  }
};
export const facebookPixelAnalyticsFactory = production => () =>
  production ? new FacebookPixelAnalyticsService() : new ConsoleLogAnalyticsService();
const routes: Routes = [
  {
    path: '',
    redirectTo: 'registration/step1',
    pathMatch: 'full'
  },

  {
    path: '**',
    component: Error404Component
  }
];

@NgModule({
  declarations: [AppComponent, Error404Component, TextCarouselComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RegistrationModule,
    RouterModule.forRoot(routes),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    HttpClientModule,
    LoginPageModule,
    StoreModule.forRoot(fromApp.appReducer)
  ],
  providers: [
    { provide: ANALYTICS_CLIENTS, useFactory: googleAnalyticsFactory(environment.ENV_NAME), multi: true },
    { provide: ANALYTICS_CLIENTS, useFactory: facebookPixelAnalyticsFactory(environment.production), multi: true },
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    { provide: 'googleTagManagerId', useValue: environment.GTM_ID }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
