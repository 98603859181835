"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var Error500Component = /** @class */ (function () {
    function Error500Component() {
        this.closeClick = new core_1.EventEmitter();
    }
    Error500Component.prototype.ngOnInit = function () { };
    return Error500Component;
}());
exports.Error500Component = Error500Component;
