import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-password-validation-tips',
  templateUrl: './password-validation-tips.component.html',
  styleUrls: ['./password-validation-tips.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({
          opacity: 0
        }),
        animate('0.3s ease-in', style({ opacity: 1 }))
      ])
    ]),
    trigger('fadeOut', [
      transition(':leave', [
        style({
          opacity: 1
        }),
        animate('0.3s ease-in', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class PasswordValidationTipsComponent implements OnInit {
  constructor() {}

  @Input() validations: Validations | undefined;

  // Describe when tips should start reacting on changes
  @Input() active: boolean;

  @Input() open: boolean;

  isValid(field: keyof Validations) {
    if (!this.active) {
      return false;
    }

    return !this.validations[field];
  }

  get loaderWidth() {
    if (!this.active) {
      return 0;
    }

    const validatorValues = Object.values(this.validations);
    const countPassed = validatorValues.reduce((a, v) => (!v ? a + 1 : a), 0);
    const percentPassed = Math.round((100 / validatorValues.length) * countPassed);

    return `${percentPassed}%`;
  }

  ngOnInit() {}
}

// Describes shape of validation rules
// Where false = passed
interface Validations {
  numbers: boolean | undefined;
  mixedCase: boolean | undefined;
  specialChars: boolean | undefined;
  length: boolean | undefined;
}
