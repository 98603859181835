import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { RegistrationFormProvider } from './registration-form.provider';
import { RegistrationParams } from '../registration/RegistrationParams';
import { environment as ENV } from '../../environments/environment';
import { AnalyticsService } from './analytics.service';
import { ExtraDataModel } from '../registration/extra-data.model';

@Injectable()
export class RegistrationProvider {
  public params: RegistrationParams;
  public formWasSubmited = false;
  domainNotAviable = false;
  mewsExtraData: ExtraDataModel;
  private extraData = {};

  private form: FormGroup;
  private BASE_URL = ENV.API_ENDPOINT;

  constructor(private http: HttpClient, registrationForm: RegistrationFormProvider) {
    this.form = registrationForm.registrationForm;
  }

  submit() {
    return new Promise((res, rej) => {
      this.http
        .post<{ errors: string; redirect: string }>([this.BASE_URL, 'register'].join('/'), this.getSubmitData(), {
          observe: 'response'
        })
        .subscribe(ok => res(ok.body.redirect), fail => rej(fail));
    });
  }

  private getSubmitData() {
    let extraData = {};

    if (this.mewsExtraData) {
      extraData = { ...extraData, ...this.mewsExtraData };
    }
    if (Object.keys(this.extraData).length !== 0) {
      extraData = { ...extraData, ...this.extraData };
    }

    return { ...this.form.value, ...{ extra_data: extraData } };
  }

  public appendExtraData(extraData = {}) {
    this.extraData = { ...this.extraData, ...extraData };
  }
}
