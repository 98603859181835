"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var EmitEventOnEnter = /** @class */ (function () {
    function EmitEventOnEnter() {
    }
    EmitEventOnEnter.prototype.onKeyPress = function (key) {
        if (this.keyIsEnter(key.code)) {
            if (this.methodToCall instanceof core_1.EventEmitter) {
                console.log('from enter direcetive');
                this.methodToCall.emit();
            }
        }
    };
    EmitEventOnEnter.prototype.keyIsEnter = function (keyCode) {
        return keyCode === 'Enter';
    };
    return EmitEventOnEnter;
}());
exports.EmitEventOnEnter = EmitEventOnEnter;
