"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Locale;
(function (Locale) {
    Locale["DEFAULT"] = "en";
    Locale["en"] = "en";
    Locale["de"] = "de";
    Locale["pl"] = "pl";
})(Locale = exports.Locale || (exports.Locale = {}));
(function (Locale) {
    function getAvaiableLocale() {
        return Object.keys(Locale);
    }
    Locale.getAvaiableLocale = getAvaiableLocale;
})(Locale = exports.Locale || (exports.Locale = {}));
