import * as ImpactTrackingDataActions from '../actions/impact-tracking-data.actions';

export interface State {
  irpid: string;
  irclickid: string;
}

const initialState: State = {
  irpid: null,
  irclickid: null
};

export function impactTrackingDataReducer(
  state: State = initialState,
  action: ImpactTrackingDataActions.ImpactTrackingDataAction
) {
  switch (action.type) {
    case ImpactTrackingDataActions.UPDATE_IRPID:
      return {
        ...state,
        irpid: action.payload
      };
    case ImpactTrackingDataActions.UPDATE_IRCLICKID:
      return {
        ...state,
        irclickid: action.payload
      };
    default:
      return state;
  }
}

export const getImpactTrackingData = (state: State) => state;
export const getImpactTrackingDataIrpid = (state: State) => state.irpid;
export const getImpactTrackingDataIrclickid = (state: State) => state.irclickid;
