"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var environment_1 = require("../../environments/environment");
var http_1 = require("@angular/common/http");
var i0 = require("@angular/core");
var i1 = require("@angular/common/http");
var CurrenciesService = /** @class */ (function () {
    function CurrenciesService(httpClient) {
        this.httpClient = httpClient;
        this.apiURLV2 = environment_1.environment.API_ENDPOINT_V2;
    }
    CurrenciesService.prototype.getCurrencies = function () {
        return this.httpClient.get(this.apiURLV2 + "/currencies");
    };
    CurrenciesService.ngInjectableDef = i0.defineInjectable({ factory: function CurrenciesService_Factory() { return new CurrenciesService(i0.inject(i1.HttpClient)); }, token: CurrenciesService, providedIn: "root" });
    return CurrenciesService;
}());
exports.CurrenciesService = CurrenciesService;
