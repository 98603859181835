"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var step1_component_1 = require("./step1/step1.component");
var step2_component_1 = require("./step2/step2.component");
var step2_resolver_1 = require("./step2/step2.resolver");
var router_animations_transitions_names_enum_1 = require("../shared/animations/router-animations-transitions-names.enum");
var can_open_step2_guard_1 = require("./step2/can-open-step2.guard");
var thankyou_component_1 = require("./thankyou/thankyou.component");
var ɵ0 = { triggerName: router_animations_transitions_names_enum_1.REGISTRATION_STEP1 }, ɵ1 = { triggerName: router_animations_transitions_names_enum_1.REGISTRATION_STEP2 };
exports.ɵ0 = ɵ0;
exports.ɵ1 = ɵ1;
var routes = [
    { path: 'registration', pathMatch: 'full', redirectTo: 'registration/step1' },
    { path: 'registration/step1', component: step1_component_1.Step1Component, data: ɵ0 },
    {
        path: 'registration/step2',
        component: step2_component_1.Step2Component,
        resolve: { formData: step2_resolver_1.Step2Resolver },
        data: ɵ1,
        canActivate: [can_open_step2_guard_1.CanOpenStep2Guard]
    },
    {
        path: 'registration/thankyou',
        component: thankyou_component_1.ThankyouComponent
    }
];
var RegistrationModule = /** @class */ (function () {
    function RegistrationModule() {
    }
    return RegistrationModule;
}());
exports.RegistrationModule = RegistrationModule;
