import { Injectable } from '@angular/core';
import { AnalyticsClient } from './analyticsClient.interface';

@Injectable()
export class FacebookPixelAnalyticsService implements AnalyticsClient {
  constructor() {}

  pageViewEvent(url) {
    fbq('track', 'PageView', { url });
  }
}
