<p class="text-center text-uppercase title mb-1" i18n>
  {{'loginHeader' | translate}}
</p>
<p class="subtitle">{{'loginSubHeader' | translate}}</p>
<form (ngSubmit)="login()" [formGroup]="form" class="flex-grow-1 d-flex">
  <div class="mt-4 d-flex flex-grow-1 flex-column">
    <div class="form-group form-group-default" [ngClass]="{'has-error': fieldError('email')}">
      <label for="email">{{'labels.email' | translate}}</label>
      <input id="email" type="email" autocomplete="email" class="form-control" formControlName="email"
             placeholder="{{'placeholders.email' | translate}}">

    </div>
    <p class="errors" *ngIf="fieldError('email')">
      <span *ngIf="fieldError('email', 'required')">{{'errors.fieldIsRequired' | translate}}</span>
      <span *ngIf="fieldError('email', 'email')">{{'errors.emailIsIncorrect' | translate}}</span>
      <span *ngIf="fieldError('email', 'subdomainInvalid')">{{'errors.subdomainInvalid' | translate}}</span>
    </p>

    <div class="form-group form-group-default" [ngClass]="{'has-error': fieldError('password')}">
      <label for="password">{{'labels.password' | translate}}</label>
      <input autocomplete="new-password"  id="password" type="password" class="form-control" formControlName="password"
             placeholder="{{'placeholders.password' | translate}}">
    </div>
    <p class="errors" *ngIf="fieldError('password')">
      <span *ngIf="fieldError('password', 'required')">{{'errors.fieldIsRequired' | translate}}</span>
      <span *ngIf="fieldError('password', 'minlength')">{{'errors.errorMinimalLengthIs' | translate}}</span>
      <span *ngIf="fieldError('password', 'subdomainInvalid')">{{'errors.subdomainInvalid' | translate}}</span>
    </p>

    <div
      [hidden]="onlyOneDomain()"
      class="form-group form-group-default form-group-default-select"
      [ngClass]="{'focused': domainFocus}">
      <label for="domains" class="text-uppercase mb-0" i18n>
        {{'labels.selectDomain' | translate}}
      </label>

      <select name="domains"
              id="domains"
              class="form-control"
              (focus)="domainFocus = true"
              (blur)="domainFocus = false"
              autofocus
              formControlName="subdomain"
      >
        <option
          *ngFor="let domain of getDomains().controls; let i = index"
          [ngValue]="domain.value.name">
          {{domain.value.name}}</option>
      </select>

    </div>
    <div class="section">
      <div class="row align-items-center">
        <div class="col">
          <div class="checkbox check-primary">
            <input id="rememberMe" type="checkbox" formControlName="rememberMe">
            <label for="rememberMe">{{'login.rememberMe' | translate}}</label>
          </div>

        </div>
        <div class="col text-right reset-password">
          <a href="https://app.vouchercart.com/reset-password">{{'login.resetPassword' | translate}}</a>
        </div>
      </div>
    </div>

    <div class="buttons mt-auto">
      <button type="submit" class="btn btn-lg  w-100" [ngClass]="{'btn-primary': true}" [disabled]="searchingDomains">
        {{'login.signIn' | translate}} <img *ngIf="logging" src="/assets/img/spinner.svg" width="12" height="12" alt="">
      </button>
    </div>
    <div class="footer-text">
      <div class="row">

        <div class="col text-left">
          <span>{{'login.help' | translate}}</span> &nbsp; <a [href]="contactURL"> <span
          class="text-primary">{{'login.contactSupport' | translate}} </span></a>
        </div>
        <div class="col-auto text-right">
          <span>{{'login.dontHaveAccount' | translate}}</span>&nbsp;&nbsp;<a
          routerLink="/registration"><span>{{'login.signUp' | translate}}</span></a>
        </div>
      </div>
    </div>
  </div>
</form>
